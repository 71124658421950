/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExpiredEmployeeDashboardApiModel } from './expiredEmployeeDashboardApiModel';


export interface EmployerDashboardApiModel { 
    employeesCount: number;
    eligibleEmployeesCount: number;
    eligibleEmployeesPlanedCount: number;
    soonExpiredEmployeesCount: number;
    soonExpiredEmployeesPlanedCount: number;
    expiredEmployeesCount: number;
    expiredEmployeesPlanedCount: number;
    subsetExpiredEmployees: Array<ExpiredEmployeeDashboardApiModel>;
}

