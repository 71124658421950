import { Component } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { AddEditUserModalComponent } from "../../../profile/components/add-edit-user-modal/add-edit-user-modal.component";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  message = 'Určite chcete pokračovať v tejto akcii?';
  cancelLabel = "Zrušiť";
  okLabel = "Potvrdiť";

  constructor(
    private ref: NbDialogRef<AddEditUserModalComponent>
  ) {}

  confirm() {
    this.ref.close(true);
  }

  cancel() {
    this.ref.close(false);
  }
}
