import { Injectable } from '@angular/core';
import { AssessmentService } from 'src/api-new/api/assessment.service';
import { CompleteAssessmentApiModel } from "src/api-new/model/completeAssessmentApiModel";
import { CreateOrderApiModel } from "src/api-new/model/createOrderApiModel";
import { Subject } from "rxjs";
import { tap } from "rxjs/operators";
import {OrderService} from "../../api-new";

@Injectable({
  providedIn: 'root'
})
export class AssessmentMiddleService {

  assessmentsChangedSubject = new Subject();

  constructor(
    private assessmentService: AssessmentService,
    private orderService: OrderService,
  ) { }

  ordersGridFilter: any;
  assessmentsGridFilter: any;

  getNotPlannedOrders(page: number, onPage: number, textSearch: string, gridFilter: any, employerId: number | undefined) {
    this.ordersGridFilter = gridFilter;
    return this.orderService.orderGetGridNotPlannedOrders(
      employerId,
      page+1,
      onPage,
      textSearch
    );
  }


  createOrders(orders: Array<CreateOrderApiModel>) {
    return this.orderService.orderCreateOrder(orders);
  }

  getAssessment(id: number) {
    return this.orderService.orderGetOrderDetails(id);
  }

  confirmOrders(ids: Array<number>) {
    return this.orderService.orderConfirmAssessment(ids);
  }



  deleteAssessment(id: number) {
    return this.orderService.orderDeleteOrder(id).pipe(
      tap(() => this.assessmentsChangedSubject.next())
    );
  }

  completeAssessment(id: number, assessment: CompleteAssessmentApiModel) {
    return this.assessmentService.assessmentCompleteAssessment(id, assessment).pipe(
      tap(() => this.assessmentsChangedSubject.next())
    );
  }

  printAssessment(id: number) {
    return this.assessmentService.assessmentCreatPdf(id);
  }

  printMultipleAssessments(ids: number[]) {
    return this.assessmentService.assessmentCreatPdf2(ids);
  }


  getOverdueCount() {
    return this.assessmentService.assessmentGetOrdersCountByStatus();
  }


}
