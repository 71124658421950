import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mins2num'
})
export class Mins2numPipe implements PipeTransform {

  transform(value: number, format?: 'hours' | 'minutes'): number {
    switch (format) {
      case 'hours':
        return Math.floor(value / 60);
      default:
        return Math.floor(value % 60);
    }
  }

}
