import { Component } from '@angular/core';
import { UserDetailService } from "../../../../services/user-detail.service";

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  today = new Date();

  constructor(
    public userDetailService: UserDetailService
  ) { }
}
