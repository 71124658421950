import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-overlay',
  templateUrl: './load-overlay.component.html',
  styleUrls: ['./load-overlay.component.scss']
})
export class LoadOverlayComponent {
  @Input() message = 'Načítavanie...';
}
