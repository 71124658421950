<form [formGroup]="fg" autocomplete="off">
  <ng-container *ngFor="let control of qf.controls">
    <textarea *ngIf="control.type === 'label' && control.value!==''"
              type="text" fullWidth
              [formControlName]="control.formControlName"
              [ngClass]="{hidden: control.hidden | async}"
              readonly class="label">{{control.value}}</textarea>

    <input *ngIf="['text', 'password', 'number'].includes(control.type)"
           autocomplete="off"
           nbInput
           [id]="control.id"
           [type]="control.type"
           [min]="control.min"
           [formControlName]="control.formControlName"
           [placeholder]="control.placeholder"
           [nbTooltip]="control.placeholder"
           [nbTooltipPlacement]="NbPosition.START"
           [ngClass]="{hidden: control.hidden | async}"
           [attr.disabled]="control.disabled"
           [readOnly]="control.readOnlyAsText">


    <input *ngIf="control.type === 'price'"
           type="text"
           (blur)="control.formatPrice()"
           nbInput
           [ngClass]="{hidden: control.hidden | async}"
           [formControlName]="control.formControlName"
           [placeholder]="control.placeholder"
           [nbTooltip]="control.placeholder"
           [nbTooltipPlacement]="NbPosition.START">

    <input *ngIf="control.type === 'companyId'"
           type="text"
           nbInput
           [ngClass]="{hidden: control.hidden | async}"
           [formControlName]="control.formControlName"
           [placeholder]="control.placeholder"
           [nbTooltip]="control.placeholder"
           [nbTooltipPlacement]="NbPosition.START">

    <input *ngIf="control.type === 'minutes'"
           type="text"
           nbInput
           [ngClass]="{hidden: control.hidden | async}"
           [formControlName]="control.formControlName"
           [placeholder]="control.placeholder"
           [nbTooltip]="control.placeholder"
           [nbTooltipPlacement]="NbPosition.START">

    <nb-select *ngIf="control.type === 'select'"
               [id]="control.id"
               [ngClass]="{hidden: control.hidden | async}"
               [disabled]="control.disabled"
               [formControlName]="control.formControlName"
               [placeholder]="control.placeholder"
               [nbTooltip]="control.placeholder"
               [nbTooltipPlacement]="NbPosition.START">
      <nb-option *ngFor="let option of control.optionsObservable | async" [value]="option.value" [title]="option.label">
        {{option.label | truncate : 35}}
      </nb-option>
    </nb-select>

    <ng-container *ngIf="control.type === 'date'">
      <input nbInput
             [ngClass]="{hidden: control.hidden | async}"
             [formControlName]="control.formControlName"
             [placeholder]="control.placeholder"
             [nbDatepicker]="datePicker"
             [nbTooltip]="control.placeholder"
             [nbTooltipPlacement]="NbPosition.START"
             [attr.disabled]="control.disabled">
      <nb-datepicker #datePicker [filter]="control.dateFilter" [min]="control.minDate"></nb-datepicker>
    </ng-container>

    <input *ngIf="control.type === 'time'"
           type="time"
           required
           nbInput
           [ngClass]="{hidden: control.hidden | async}"
           [formControlName]="control.formControlName"
           [placeholder]="control.placeholder"
           [nbTooltip]="control.placeholder"
           [nbTooltipPlacement]="NbPosition.START">

    <ng-container *ngIf="control.type === 'check'">
      <nb-checkbox [formControlName]="control.formControlName" [disabled]="control.disabled">{{control.placeholder}}</nb-checkbox>
    </ng-container>

  </ng-container>
</form>
