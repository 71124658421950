<nb-card>
  <nb-card-body>
    <h3>Výber poskytovateľa</h3>
    <nb-select fullWidth size="small" [(selected)]="selectedIdx">
      <nb-option *ngFor="let provider of providers; let idx = index" [value]="idx">
        {{provider.name}}
      </nb-option>
    </nb-select>
    <div *ngIf="chooseOrderType">
      <h3>Typ objednávky</h3>
      <nb-select fullWidth size="small" [(selected)]="selectedIda" [disabled]="!employeeEntryExamination">
        <nb-option *ngFor="let assessmentType of assessmentTypes; let ida = index" [value]="ida">
          {{getExaminationTypeName(assessmentType)}}
        </nb-option>
      </nb-select>
    </div>
    <div class="modal-actions">
      <button nbButton size="small" status="basic" (click)="cancel()">Zrušiť</button>
      <div *ngIf="chooseOrderType; then orderWithType else orderWithoutType"></div>
      <ng-template #orderWithType>
        <button nbButton size="small" status="primary" (click)="confirm()" [disabled]="!selectedIdx && selectedIdx !== 0 && !selectedIda && selectedIda !==0">Potvrdiť</button>
      </ng-template>
      <ng-template #orderWithoutType>
        <button nbButton size="small" status="primary" (click)="confirm()" [disabled]="!selectedIdx && selectedIdx !== 0">Potvrdiť</button>
      </ng-template>
    </div>
  </nb-card-body>
</nb-card>
