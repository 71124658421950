import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GridfilterService } from 'src/app/services/gridfilter.service';

export type GridFilterNameType = ('name' | 'firstName' | 'employer' | 'occupation' | 'riskFactor' | 'workActivity' | 'medicalExamination');

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss']
})
export class GridFilterComponent implements OnInit, AfterViewInit {

  gridFilter: any;

  @Input() gridFilterItems: any;
  @Output() gridFilterEmitter = new EventEmitter<{}>();

  @ViewChild('inputName') inputName: ElementRef;
  @ViewChild('inputSurname') inputSurname: ElementRef;
  @ViewChild('inputWorkplace') inputWorkplace: ElementRef;

  constructor(
    private gridFilterService: GridfilterService
  ) {
  }

  ngOnInit(): void {
    this.initGridFilter();
  }

  ngAfterViewInit() {
    [this.inputName, this.inputSurname, this.inputWorkplace].forEach(el => {
      if(el) {
        fromEvent<any>(el.nativeElement, 'keyup').pipe(
          map(event => event.target.value),
          debounceTime(300),
          distinctUntilChanged()
        )
        .subscribe((res) => {
          this.emit();
        });
      }
    })
  }

  getNamePlaceholder(nameType: GridFilterNameType) {
    switch (nameType) {
      case 'name' || 'firstName': return 'Meno';
      case 'employer': return 'Zamestnávateľ';
      case 'occupation': return 'Profesia';
      case 'riskFactor': return 'Faktor';
      case 'workActivity': return 'Činnosť';
      case 'medicalExamination': return 'Faktor/Činnosť';

      default: return '';
    }
  }

  getNameTooltip(nameType: GridFilterNameType) {
    switch (nameType) {
      case 'name' || 'firstName': return 'Hľadať meno';
      case 'employer': return 'Hľadať zamestnávateľa';
      case 'occupation': return 'Hľadať profesiu';
      case 'riskFactor': return 'Hľadať rizikový faktor';
      case 'workActivity': return 'Hľadať vybranú činnosť';
      case 'medicalExamination': return 'Hľadať faktor/činnosť';

      default: return '';
    }
  }

  emit() {
    this.gridFilterEmitter.emit(this.gridFilter);
  }

  initGridFilter() {
    let filter = new Object;

    if(this.gridFilterItems.id) filter[this.gridFilterItems.id.type] = {val: 0, condition: 'GREATER_OR_EQUAL'};
    if(this.gridFilterItems.surname) filter[this.gridFilterItems.surname.type] = {val: '', condition: 'CONTAINS'};
    if(this.gridFilterItems.name) filter[this.gridFilterItems.name.type] = {val: '', condition: 'CONTAINS'};
    if(this.gridFilterItems.dateOfBirth) filter[this.gridFilterItems.dateOfBirth.type] = {val: '', condition: 'EQUALS', lower: false, greater: false};
    if(this.gridFilterItems.personalNumber) filter[this.gridFilterItems.personalNumber.type] = {val: '', condition: 'CONTAINS'};
    if(this.gridFilterItems.employerId) filter[this.gridFilterItems.employerId.type] = {val: 0, condition: 'GREATER_OR_EQUAL'};
    if(this.gridFilterItems.employerName) filter[this.gridFilterItems.employerName.type] = {val: '', condition: 'CONTAINS'};
    if(this.gridFilterItems.age) filter[this.gridFilterItems.age.type] = {val: 0, condition: 'GREATER_OR_EQUAL'};
    if(this.gridFilterItems.workplace) filter[this.gridFilterItems.workplace.type] = {val: '', condition: 'CONTAINS'};
    this.gridFilter = Object.assign({}, filter);
    this.emit()
    this.gridFilterService.setFilter(this.gridFilter);
  }

  updateGridFilter(filter) {
    if(filter) {
      if(this.gridFilterItems.employerName) this.gridFilter.employerName.val = filter.employerName ? filter.employerName.toString() : this.gridFilter.employerName.val;
      if(this.gridFilterItems.surname) this.gridFilter.surname.val = filter.surname ? filter.surname.toString() : this.gridFilter.surname.val;
      if(this.gridFilterItems.name) this.gridFilter.name.val = filter.name ? filter.name.toString() : this.gridFilter.name.val;
      if(this.gridFilterItems.dateOfBirth) {
        if(filter.dateOfBirth === null) {
          this.gridFilter.dateOfBirth.val = '';
          this.gridFilter.dateOfBirth.lower = false;
          this.gridFilter.dateOfBirth.greater = false;
        } else {
          this.gridFilter.dateOfBirth.val = filter.dateOfBirth && filter.dateOfBirth !== null ? filter.dateOfBirth.toLocaleString() : this.gridFilter.dateOfBirth.val;
        }
      }
    }
    this.gridFilterService.setFilter(this.gridFilter);
  }

  changeDateCondition(condition: String) {
    if(this.gridFilter.dateOfBirth?.val) {
      switch (condition) {
        case 'lower': {
          if(this.gridFilter.dateOfBirth.greater) {
            this.gridFilter.dateOfBirth.greater = false;
            this.gridFilter.dateOfBirth.lower = true;
            this.gridFilter.dateOfBirth.condition = 'LOWER_OR_EQUAL';
          } else {
            this.gridFilter.dateOfBirth.lower = !this.gridFilter.dateOfBirth.lower;
            this.gridFilter.dateOfBirth.condition = this.gridFilter.dateOfBirth.lower ? 'LOWER_OR_EQUAL' : 'EQUALS';
          }
          break;
        }
        case 'greater': {
          if(this.gridFilter.dateOfBirth.lower) {
            this.gridFilter.dateOfBirth.lower = false;
            this.gridFilter.dateOfBirth.greater = true;
            this.gridFilter.dateOfBirth.condition = 'GREATER_OR_EQUAL';
          } else {
            this.gridFilter.dateOfBirth.greater = !this.gridFilter.dateOfBirth.greater;
            this.gridFilter.dateOfBirth.condition = this.gridFilter.dateOfBirth.greater ? 'GREATER_OR_EQUAL' : 'EQUALS';
          }
          break;
        }
        default: break;
      }
      this.emit()
    }
  }
}
