import { animate, animation, style, transition, trigger, useAnimation } from "@angular/animations";

export const fadeInAnimation = animation([
  style({opacity: 0}),
  animate('{{ time }}', style({opacity: 1}))
]);

export const fadeOutAnimation = animation([
  animate('{{ time }}', style({opacity: 0}))
]);

export function fadeInOutTrigger(duration: number) {
  return trigger('fadeInOut', [
    transition(':enter', [
      useAnimation(fadeInAnimation, {params: {time: duration + 'ms'}})
    ]),
    transition(':leave', [
      useAnimation(fadeOutAnimation, {params: {time: duration + 'ms'}})
    ])
  ]);
}
