import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { catchError } from "rxjs/operators";
import {getBasePath} from "../../config";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.createNewRequest(req)).pipe(
      catchError(errorResponse => {
        if (errorResponse.status === 401) {
          this.authService.logOut();
        }
        return throwError(errorResponse);
      })
    );
  }

  private createNewRequest(req: HttpRequest<any>, newToken?: string) {
    let newRequest = req.clone();
    // Dont send a possibly wrong token, when trying to login again
    if (req.url !== getBasePath() + '/api/Auth/login') {
      const token = newToken ? newToken : this.authService.jwt;
      if (token) {
        const headers = newRequest.headers.append('Authorization', 'Bearer ' + token);
        newRequest = req.clone({headers});
      }
    }
    return newRequest;
  }
}
