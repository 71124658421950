import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-remove-tenant-dialog',
  templateUrl: './remove-tenant-dialog.component.html',
  styleUrls: ['./remove-tenant-dialog.component.scss']
})
export class RemoveTenantDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveTenantDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
