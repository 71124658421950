<div class="grid-filter">
    <div class="filter">
        <nb-icon class="" icon="funnel-outline"></nb-icon> Filter:
    </div>

    <div *ngIf="gridFilterItems.employerName && gridFilterItems.employerName.visible" class="item">
        <nb-form-field>
        <nb-icon nbPrefix icon="search-outline"></nb-icon>
        <input type="text" nbInput fieldSize="tiny"
            [(ngModel)]="gridFilter.employerName.val"
            (ngModelChange)="updateGridFilter({employerName: $event})"
            [placeholder]="'Zamestnávateľ'"
            [nbTooltip]="'Hľadať zamestnávateľa'">
        </nb-form-field>
    </div>

    <div *ngIf="gridFilterItems.surname && gridFilterItems.surname.visible" class="item">
        <nb-form-field>
        <nb-icon nbPrefix icon="search-outline"></nb-icon>
        <input #inputSurname type="text" nbInput fieldSize="tiny"
            [(ngModel)]="gridFilter.surname.val"
            (ngModelChange)="updateGridFilter({surname: $event})"
            [placeholder]="'Priezvisko'"
            [nbTooltip]="'Hľadať priezvisko'">
        </nb-form-field>
    </div>

    <div *ngIf="gridFilterItems.name && gridFilterItems.name.visible" class="item">
        <nb-form-field>
        <nb-icon nbPrefix icon="search-outline"></nb-icon>
        <input #inputName type="text" nbInput fieldSize="tiny"
            [(ngModel)]="gridFilter.name.val"
            (ngModelChange)="updateGridFilter({name: $event})"
            [placeholder]="getNamePlaceholder(gridFilterItems.name.nameType)"
            [nbTooltip]="getNameTooltip(gridFilterItems.name.nameType)">
        </nb-form-field>
    </div>

    <div *ngIf="gridFilterItems.workplace && gridFilterItems.workplace.visible" class="item">
        <nb-form-field>
        <nb-icon nbPrefix icon="search-outline"></nb-icon>
        <input #inputWorkplace type="text" nbInput fieldSize="tiny"
            [(ngModel)]="gridFilter.workplace.val"
            (ngModelChange)="updateGridFilter({workplace: $event})"
            [placeholder]="'Pracovisko'"
            [nbTooltip]="'Hľadať pracovisko'">
        </nb-form-field>
    </div>

    <div *ngIf="gridFilterItems.dateOfBirth && gridFilterItems.dateOfBirth.visible" class="item">
        <nb-icon id="date-lower" class="chevron lower" icon="chevron-left-outline"
        [nbTooltip]="'Dátum narodenia pred (vrátane)'"
        (click)="changeDateCondition('lower')"
        [ngClass]="{active: gridFilter.dateOfBirth.lower, pointer: gridFilter.dateOfBirth.val}">
        </nb-icon>
        <nb-form-field>
        <nb-icon nbPrefix icon="calendar-outline"></nb-icon>
        <input nbInput name="dateOfBirth" required fieldSize="tiny"
            [(ngModel)]="gridFilter.dateOfBirth.val"
            (ngModelChange)="updateGridFilter({dateOfBirth: $event})"
            [placeholder]="'Dátum'"
            [nbTooltip]="'Vybrať dátum'"
            [nbDatepicker]="datepicker">
        <nb-datepicker #datepicker></nb-datepicker>
        </nb-form-field>
        <nb-icon id="date-greater" class="chevron greater" icon="chevron-right-outline"
        [nbTooltip]="'Dátum narodenia po (vrátane)'"
        (click)="changeDateCondition('greater')"
        [ngClass]="{active: gridFilter.dateOfBirth.greater, pointer: gridFilter.dateOfBirth.val}">
        </nb-icon>
    </div>
    <ng-content></ng-content>
</div>

