<app-dialog-wrapper [actions]="actions">
  <div class="evaluation-body">
    <div class="two-column-layout">
      <div class="left-column">
        <p>Poskytovateľ:</p>
        <nb-card>
          <nb-card-body>
            <div class="info-card-grid">
              <div class="info-card-item">
                <span class="light">Obchodné meno</span><br>
                {{dialogProvider}}
              </div>
            </div>
          </nb-card-body>
        </nb-card>
        <p>Zamestnávateľ:</p>
        <nb-card>
          <nb-card-body>
            <div class="info-card-grid">
              <div class="info-card-item">
                <span class="light">Obchodné meno</span><br>
                {{dialogOrder?.employer.name}}
              </div>
              <div class="info-card-divider"></div>
              <div class="info-card-item">
                <span class="light">IČO</span><br>
                {{dialogOrder?.employer.ico | companyid}}
              </div>
              <div class="info-card-divider"></div>
              <div class="info-card-item">
                <span class="light">Sídlo</span><br>
                {{dialogOrder?.employer.inlineAddress}}
              </div>
            </div>
          </nb-card-body>
        </nb-card>
        <p>Zamestnanec:</p>
        <nb-card>
          <nb-card-body>
            <div class="info-card-grid">
              <div class="info-card-item">
                <span class="light">Meno a priezvisko</span><br>
                {{dialogOrder?.employee.name}} {{dialogOrder?.employee.surname}}
              </div>
              <div class="info-card-divider"></div>
              <div class="info-card-item">
                <span class="light">Dátum narodenia</span><br>
                {{dialogOrder?.employee.dateOfBirth | date}}
              </div>
              <div class="info-card-divider"></div>
              <div class="info-card-item">
                <span class="light">Trvalý pobyt</span><br>
                {{dialogOrder?.employee.inlineAddress}}
              </div>
              <div class="info-card-item">
                <span class="light">Profesia</span><br>
                {{dialogOrder?.occupation.name}}
              </div>
              <div class="info-card-divider"></div>
              <div class="info-card-item">
                <span class="light">Osobné číslo</span><br>
                {{dialogOrder?.employee.personalNumber}}
              </div>
              <div class="info-card-divider"></div>
              <div class="info-card-item">
                <span class="light">Pracovisko</span><br>
                {{dialogOrder?.workplace.name}}
              </div>
            </div>
          </nb-card-body>
        </nb-card>
        <p>Stav objednávky :</p>
        <nb-card>
          <nb-card-body>
            <div>
              <span>{{dialogAppointmentStatus != 'FINISHED' ? 'Naplánovaná ' : 'Dokončená'}}</span>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="right-column">
        <p>Faktory :</p>
        <nb-card>
          <nb-card-body>
            <div class="cards">
              <ul>
                <li *ngFor="let risk of dialogOrder?.assessedRiskFactors">{{risk.code}}{{risk.categoryId}} - {{risk.name}}</li>
              </ul>
            </div>
          </nb-card-body>
        </nb-card>
        <p>Pracovné činosti :</p>
        <nb-card>
          <nb-card-body>
            <div class="cards">
              <ul>
                <li *ngFor="let workActivity of dialogOrder?.assessedWorkActivities">{{workActivity.code}} - {{workActivity.name}}</li>
              </ul>
            </div>
          </nb-card-body>
        </nb-card>
        <p>Medicínske výkony :</p>
        <nb-card>
          <nb-card-body>
            <div class="cards">
            <ul>
              <li *ngFor="let examination of dialogOrder?.examinations">{{examination.name}}</li>
            </ul>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</app-dialog-wrapper>
