import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time2mins'
})
export class Time2minsPipe implements PipeTransform {

  transform(value: string): string {
    const [hours, minutes, seconds] = value.split(":");
    return (parseInt(hours) * 60 + parseInt(minutes)).toString() + ' min.';
  }
}

