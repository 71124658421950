/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeContractCreateApiModel } from './employeeContractCreateApiModel';
import { AddressApiModel } from './addressApiModel';


export interface CreateEmployeeApiModel { 
    name: string;
    surname: string;
    dateOfBirth: string;
    email?: string;
    phoneNumber?: string;
    personalNumber?: string;
    birthNumber?: string;
    active?: boolean;
    contracts: Array<EmployeeContractCreateApiModel>;
    addressDto: AddressApiModel;
}

