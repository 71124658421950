/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentStatus } from './assessmentStatus';
import { AssessmentResult } from './assessmentResult';


export interface EmployeeReportApiModel { 
    id: number;
    orderDate?: string;
    issueDate: string;
    plannedDate?: string;
    createDate: string;
    number: string;
    status: AssessmentStatus;
    providerId?: number;
    providerName?: string;
    orderResultStatus: AssessmentResult;
}

