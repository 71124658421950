import { Component, Input } from '@angular/core';
import {EmployeeGridList} from "../../../employees/components/employees-data-grid/employees-data-grid.component";

@Component({
  selector: 'app-employees-data-grid-status',
  templateUrl: './employees-data-grid-status.component.html',
  styleUrls: ['./employees-data-grid-status.component.scss']
})
export class EmployeesDataGridStatusComponent {
  @Input() row: boolean;
}
