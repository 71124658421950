import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TableLoaderService {

  private _loading = new BehaviorSubject<boolean>(false);
  loading$ = this._loading.asObservable();

  constructor(
  ) { }

  public set(value: boolean) {
    this._loading.next(value);
  }

  public reset() {
    this._loading.next(false);
  }

}
