<div class="column">
  <div class="header">
    <div class="header-left">
      <div matBadge="{{numberOfOccupation}}" matBadgeOverlap="false" class="badge">
      <span class="title">
         {{fromCategorization ? 'Kategorizácia' : 'Profesie' }}
      </span>
      </div>
      <div class="divider"></div>
      <div class="search">
        <nb-form-field class="search-form-field">
          <nb-icon nbPrefix icon="search-outline"></nb-icon>
          <input type="text" nbInput [readOnly]="currentEmployerId == undefined || null"
                 [(ngModel)]="nameSearch"
                 (ngModelChange)="searchByName({employerName: $event})"
                 [placeholder]="'Vyhľadať profesiu, pracovisko'"
                 [nbTooltip]="'Vyhľadať profesiu, pracovisko'">
        </nb-form-field>
      </div>
    </div>
    <div class="header-right">
      <div>
        <div class="icon" (click)="openFilter()">
          <div matBadge="{{getNumFilters()}}" matBadgeOverlap="false" class="badge">
            <mat-icon  class="setting-icon">tune</mat-icon>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="button-group">
        <app-add-button
          title="Pridať profesiu"
          (click)="addOccupation()">
        </app-add-button>
      </div>
    </div>
  </div>
  <div class="header" *ngIf="fromCategorization">
    <div class="header-left">
      <div class="dot-row" *ngIf="activeOccupationCategorization?.id === currentOccupationCategorization?.id">
        <div class="whole-dot">
      <img src="../../../../../assets/dot.jpg" alt="dot"/>
        </div>
      </div>
      <span class="title-categorization">
         {{currentEmployer?.name}}
      </span>
      <div class="number-categorization-group" *ngIf="currentOccupationCategorization || activeOccupationCategorization">
          <span class="number-categorization">
          {{activeOccupationCategorization?.id === currentOccupationCategorization?.id ? activeOccupationCategorization?.categorizationName : currentOccupationCategorization?.categorizationName }}
      </span>
      </div>

    </div>
    <div class="header-right">
      <div class="button-group">
        <app-add-button
          [icon]="'edit'"
          title="Upraviť kategorízáciu"
          (click)="editCategorization()">
        </app-add-button>
      </div>
    </div>
  </div>
</div>

<ngx-datatable
  #myTable
  class="fix-scroller-width"
  [columnMode]="'flex'"
  [headerHeight]="45"
  [footerHeight]="35"
  [rowHeight]="60"
  [rows]="data"
  [messages]="{emptyMessage: 'Žiadne dáta na zobrazenie', totalMessage: 'spolu', selectedMessage: 'vybraných'}"
  [rowClass]="getRowClass"
  [loadingIndicator]="loading$ | async"
  [sorts]="sorts"
  (sort)="onSort($event)"
  (activate)="onRowActivate($event)"
>

  <ngx-datatable-column name="Názov profesií" prop="name" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <span>{{row.name}}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column style="text-align: center;" name="Kompletná periodická prehliadka" prop="allRiskFactorExam" [flexGrow]="2" >
    <ng-template ngx-datatable-cell-template let-row="row">
      <div class="table-icon-center">
        <mat-icon
          *ngIf="row.allRiskFactorExam"
          style="color: green">
          done
        </mat-icon>
      </div>

    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Stav profesie" prop="valid" [flexGrow]="3" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <app-employees-data-grid-status
        [row]="row.valid">
      </app-employees-data-grid-status>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Počet rizikových faktorov a pracovných činností"
    prop="riskAndWorkActivities"
    [flexGrow]="3"
    [resizeable]="false"
    [sortable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <div class="row"
           *ngIf="row.numberOfRiskFactor === 0 && row.numberOfWorkActivity === 0 && fromCategorization === false; else elseBlock"
            (click)="onCustomCellClick()">
        <mat-icon style="color: #f10e0e">warning_amber</mat-icon>
        <div class="vertical-divider"></div>
        <div class="second-row">
          <span>Prejsť na kategorizáciu</span>
          <mat-icon>arrow_right_alt</mat-icon>
        </div>
      </div>
      <ng-template #elseBlock>
      <div class="table-align-start">
        <mat-icon *ngIf="row.numberOfRiskFactor === 0 && row.numberOfWorkActivity === 0 && fromCategorization" style="color: #f10e0e">warning_amber</mat-icon>
        <div *ngIf="row.numberOfRiskFactor !== 0 || row.numberOfWorkActivity !== 0 && fromCategorization" style="display: block;width: 24px;"></div>
        <div class="bubble">
            <span>{{ row.numberOfRiskFactor }}</span>
          </div>
          <div>rizikových faktorov</div>
          <div class="vertical-divider"></div>
          <div class="bubble">
            <span>{{ row.numberOfWorkActivity }}</span>
          </div>
          <div>pracovných činností</div>
        </div>
      </ng-template>
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>
