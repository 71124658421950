/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type AssessmentResult = 'ELIGIBLE' | 'NONELIGIBLE' | 'LIMITED' | 'NONE';

export const AssessmentResult = {
    ELIGIBLE: 'ELIGIBLE' as AssessmentResult,
    NONELIGIBLE: 'NONELIGIBLE' as AssessmentResult,
    LIMITED: 'LIMITED' as AssessmentResult,
    NONE: 'NONE' as AssessmentResult
};

