<nb-card [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
    <nb-card-header>
        <h3>Pridanie rizikových faktorov alebo činností</h3>
    </nb-card-header>
    <app-grid-filter [gridFilterItems]="gridFilterItems" *ngIf="showAll">
        <div class="search">
            <input id="searchName" type="text" nbInput [placeholder]="'zadajte text...'" [(ngModel)]="queryName">

            <nb-icon nbPrefix icon="search-outline" class="nbicon"></nb-icon>
            <button nbButton ghost shape="round" [class.hidden]="!queryName" (click)="clearSearch()"><nb-icon
                    icon="close-outline"></nb-icon></button>
        </div>
        <div class="divtool">
            <nb-radio-group [(ngModel)]="queryDtype" (change)="clearSearch()">
                <nb-radio value="RiskFactor">Faktory</nb-radio>
                <nb-radio value="WorkActivity">Činnosti</nb-radio>
            </nb-radio-group>
        </div>
    </app-grid-filter>

    <ngx-datatable
      *ngIf="showAll&&!loading"
      [columnMode]="'force'"
      [headerHeight]="35"
      [footerHeight]="0"
      [rowHeight]="calcRowHeight"
      [rows]="data"
      [messages]="{emptyMessage: 'Žiadne dáta na zobrazenie', totalMessage: 'spolu', selectedMessage: 'vybraných'}"
      [rowClass]="getRowClass"
      [externalSorting]="false">

        <ngx-datatable-column name="select" [resizeable]="true" [flexGrow]="1" [minWidth]="60" [maxWidth]="60">
            <ng-template ngx-datatable-header-template>
                <div class="table-checkbox-padding" style="display: inline-block;">
                    <nb-checkbox [checked]="allRowsSelected" [indeterminate]="someRowsSelected"
                        (checkedChange)="toggleAllRows($event)">
                    </nb-checkbox>
                </div>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="table-checkbox-padding">
                    <nb-checkbox [checked]="row.isSelected" [disabled]="row.isDisabled"  [indeterminate]="row.partiallySelected"
                        (checkedChange)="toggleRow($event, row)">
                    </nb-checkbox>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="name" [resizeable]="true" [flexGrow]="3">
            <ng-template ngx-datatable-header-template>
                <span id="hdrMeno" style="display: inline-block;vertical-align: middle;">Meno</span>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-row="row">
                <div>{{row.name}}</div>
                <div class="specification">{{row.specification}}</div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="code" [resizeable]="true" [flexGrow]="1" [minWidth]="60" [maxWidth]="150">
            <ng-template ngx-datatable-header-template>
                Kód
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="validity" [resizeable]="true" [flexGrow]="2" [minWidth]="60" [maxWidth]="150">
            <ng-template ngx-datatable-header-template>
                Platnosť [mes.]
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>

    <nb-card-body *ngIf="!showAll">
        <div class="list-title">Rizikové faktory:</div>
        <ng-container *ngFor="let item of data">
            <div *ngIf="item.dtype=='RiskFactor'" class="item">{{item.name}}</div>
        </ng-container>
        <div class="list-title">Vybrané činnosti:</div>
        <ng-container *ngFor="let item of data">
            <div *ngIf="item.dtype=='WorkActivity'" class="item">{{item.name}}</div>
        </ng-container>
    </nb-card-body>

    <nb-card-footer *ngIf="!loading">
        <button nbButton size="small" status="primary" [disabled]="showAll || !getCountSelectedRows()"
            (click)="save()">Zapísať</button>
        <button nbButton size="small" outline status="primary" (click)="showAll=true" *ngIf="!showAll">Zobraziť
            výber</button>
        <button nbButton size="small" outline status="primary" (click)="showAll=false" *ngIf="showAll">Vybraté
            položky</button>
        <button nbButton size="small" (click)="cancel()">Zrušiť</button>

    </nb-card-footer>
</nb-card>
