<div class="menu-container">
  <div class="menu-header">
    <div class="menu-header-left">
      <span class="menu-title">Všetky filtre</span>
    </div>
    <div class="menu-header-right">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
  <div class="horizontal-divider"></div>
  <div class="menu-content">
    <div class="menu-content-left">
    </div>
    <div class="divider-vertical"></div>
    <div class="menu-content-right">
      <form [formGroup]="filterForm" style="flex: 1">
                <div class="checkbox-group">
                  <div class="checkbox-item" (click)="toggleCheckbox('filterValidOccupation')">
                    <mat-checkbox
                      [checked]="filterForm.get('filterValidOccupation')?.value"
                      [formControlName]="'filterValidOccupation'"
                      (click)="$event.stopPropagation()">
                    </mat-checkbox>
                    <span>aktívny</span>
                    <span> - </span>
                    <span>zobrazi všetky aktívne profesie</span>
                  </div>
                  <div class="checkbox-item" (click)="toggleCheckbox('filterInactiveOccupation')">
                    <mat-checkbox
                      [checked]="filterForm.get('filterInactiveOccupation')?.value"
                      [formControlName]="'filterInactiveOccupation'"
                      (click)="$event.stopPropagation()">
                    </mat-checkbox>
                    <span>neaktívny</span>
                    <span> - </span>
                    <span>zobrazi všetky neaktívne profesie</span>
                  </div>
                </div>
      </form>
      <div class="buttons-group">
        <div class="button-active" (click)="save()">
          <span>Potvrdiť filtre</span>
        </div>
        <div class="button" (click)="default()">
          <span>Zrušiť filter <mat-icon>close</mat-icon></span>
        </div>
      </div>
    </div>
  </div>
</div>
