/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type AssessmentTypeEnum = 'INPUT' | 'OUTPUT' | 'PERIOD' | 'REGULAR' | 'IMPORT';

export const AssessmentTypeEnum = {
    INPUT: 'INPUT' as AssessmentTypeEnum,
    OUTPUT: 'OUTPUT' as AssessmentTypeEnum,
    PERIOD: 'PERIOD' as AssessmentTypeEnum,
    REGULAR: 'REGULAR' as AssessmentTypeEnum,
    IMPORT: 'IMPORT' as AssessmentTypeEnum
};

