import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbPosition } from '@nebular/theme';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export interface QuickControl {
  disabled?: boolean;
  hidden?: Observable<boolean>;
  formControlName: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'number' | 'companyId' | 'select' | 'date' | 'check' | 'time' | 'price' | 'minutes' | 'datetime' | 'label';
  validators?: ValidatorFn[];
  formError?: string;
  optionsObservable?: Observable<Array<{label: string, value: any}>>;
  dateFilter?: (date: Date) => boolean;
  minDate?: Date;
  min?: number;
  formatPrice?: () => void;
  id?: string;
  value?: string;
  readOnlyAsText?: boolean;
}

export interface QuickForm {
  controls: QuickControl[];
}

@Component({
  selector: 'app-quick-form',
  templateUrl: './quick-form.component.html',
  styleUrls: ['./quick-form.component.scss']
})
export class QuickFormComponent {

  NbPosition = NbPosition;

  fg: UntypedFormGroup;
  qf: QuickForm;
  @Input() set formDef(value: QuickForm) {
    this.qf = value;
    this.initForm();
  }

  @Output() formGroupInit = new EventEmitter<UntypedFormGroup>();

  constructor() {
  }

  initForm() {
    this.fg = new UntypedFormGroup({});
    this.qf.controls.forEach(control => this.fg.addControl(
      control.formControlName,
      new UntypedFormControl(null, control.validators)
    ));
    this.formGroupInit.emit(this.fg);
  }
}
