import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-occupation-filter-dialog',
  templateUrl: './occupation-filter-dialog.component.html',
  styleUrls: ['./occupation-filter-dialog.component.scss']
})
export class OccupationFilterDialogComponent implements OnInit{
  filterForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<OccupationFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formInit();
  }

  formInit(): void {
    this.filterForm = this.fb.group({
      filterValidOccupation: this.fb.control(this.data.filterValidOccupation || false),
      filterInactiveOccupation: this.fb.control(this.data.filterInactiveOccupation || false),
    });
  }

  toggleCheckbox(controlName: string): void {
    const control = this.filterForm.get(controlName);
    if (control && control.enabled) {
      control.setValue(!control.value);
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  default(): void {
    this.dialogRef.close({
      filterValidOccupation: true,
      filterInactiveOccupation: false
    });
  }

  save(): void {
    this.dialogRef.close(this.filterForm.value);
  }
}
