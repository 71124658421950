import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';


const routes: Routes = [
  {
    path: 'prihlasenie',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NotLoggedInGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/interface/interface.module').then(m => m.InterfaceModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'zabudnute-heslo',
    loadChildren: () => import('./modules/forgotten-password/forgotten-password.module').then(m => m.ForgottenPasswordModule)
  },
  {
    path: 'reset-hesla',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'accept-invitation',
    loadChildren: () => import('./modules/accept-invitation/accept-invitation.module').then(m => m.AcceptInvitationModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
