import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import {mergeMap, switchMap, tap} from 'rxjs/operators';
import {
  OccupationAssessibleItemApiModel, OccupationAssessibleItemUpdateApiModel, OccupationCategorizationService,
  OccupationService
} from 'src/api-new';
import { OverlayLoaderService } from 'src/app/services/overlay-loader.service';
import { UserDetailService } from 'src/app/services/user-detail.service';
import { AddFactorActivityModalComponent } from '../add-factor-activity-modal/add-factor-activity-modal.component';

@Component({
  selector: 'app-occupation-detail',
  templateUrl: './occupation-detail.component.html',
  styleUrls: ['./occupation-detail.component.scss']
})
export class OccupationDetailComponent implements OnInit {

  @Input() employerId: number;
  @Input() occupationId: number;
  @Input() occupationName: string;
  @Input() categorizationId: number;

  userRole = this.userDetailService.role;

  occupationDetails$: Observable<OccupationAssessibleItemApiModel>;
  loading$: Observable<boolean>;

  occupationDetails: OccupationAssessibleItemApiModel;
  assessibleItemsIds: number[];

  refreshData = new BehaviorSubject(null);

  constructor(
    private overlayLoader: OverlayLoaderService,
    private userDetailService: UserDetailService,
    private categorizationService: OccupationCategorizationService,
    private nbDialog: NbDialogService,
    private nbToastr: NbToastrService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loading$ = this.overlayLoader.loading$;
    this.overlayLoader.set(true);
    this.occupationDetails$ = this.refreshData.pipe(
      mergeMap(() => {
        return this.activatedRoute.queryParams.pipe(
          switchMap((params) => {
            return this.categorizationService.occupationCategorizationGetOccupationDetails(this.categorizationId, this.occupationId).pipe(
              tap((res) => {
                this.occupationDetails = res;
                this.assessibleItemsIds = [...res.riskFactors, ...res.workActivities].map(item => item.id);
              }),
              tap(() => this.overlayLoader.set(false))
            );
          })
        );
      })
    );
  }

  addItem() {
    this.nbDialog.open(
      AddFactorActivityModalComponent,
      {
        context: {
          occupationId: this.occupationId,
          employerId: this.employerId,
          selectedIds: this.assessibleItemsIds
        }
      }
    ).onClose.subscribe(
      res => {
        if (res) {
          this.refreshData.next(null);
        }
      }
    );
  }

  removeItem(occupationId: number, assessibleItemId: number) {
    this.overlayLoader.set(true);
    const occupationAssessibleItems: OccupationAssessibleItemUpdateApiModel = {
      occupationId,
      categorizationId: this.categorizationId,
      assessibleItemIds: this.assessibleItemsIds
        .filter(id => id !== assessibleItemId)
    };
    this.categorizationService.occupationCategorizationAssignAssessibleItemsToOccupation(occupationAssessibleItems).subscribe(
      () => {
        this.refreshData.next(null);
      },
      (err) => {
        this.nbToastr.danger('Chyba v komunikácii so serverom', err.status, {duration: 5000});
        this.overlayLoader.set(false);
      }
    )
  }
}
