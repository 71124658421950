<div style="position: relative">
  <nb-user size="giant"
           [picture]="userDetailService.profilePicture"
           name="Dobrý deň,&nbsp;"
           [title]="userDetailService.userName"
           [showInitials]="false"
  ></nb-user>
  <div class="thin-font" style="position: absolute; top: 50px; left: 72px">
    {{userDetailService.tenantName}}
  </div>
</div>
<div class="date">
  {{today | date : 'd. MMMM yyyy'}}, {{today | date : 'EEEE'}}
</div>
