import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'companyid'
})
export class CompanyIdPipe implements PipeTransform {

    transform(value: string): string {
        let companyId: string;
        if (value !== null && value.trim().length === 8) {
            companyId = value.trim();
            companyId = companyId.substr(0, 2) + ' ' + companyId.substr(2, 3) + ' ' + companyId.substr(5, 3);
            return companyId;
        } else return value;
    }
}

