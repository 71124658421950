import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomIconData, CustomIconDataToRegister } from "../interfaces/custom-icon.interface";
import { Observable, forkJoin, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  public iconDataToRegister$ = (
    customIconsData: CustomIconData[]
  ): Observable<CustomIconDataToRegister> => {
    const customIcons$ = {};

    customIconsData.forEach((icon) => {
      customIcons$[icon.name] = this.httpClient.get(`${icon.path}`, {responseType: 'text'});
    });

    return forkJoin({...customIcons$}).pipe(
      switchMap((icons) => {
        const iconsDataToRegister: CustomIconDataToRegister = {};

        Object.keys(icons).forEach((key) => {
          iconsDataToRegister[key] = (icons[key] as string).replace('\n', '')
        });
        return of(iconsDataToRegister);
      })
    );
  }
}