<app-dialog-wrapper [actions]="actions">
  <h3>Vyhladať na základe IČO</h3>
  <div class="business-id-input">
  <input maxlength="8" class="autocompleteInput" [formControl]="businessId" [readOnly]="disabledSelected" nbInput name="ico" [placeholder]="'IČO'"
         [nbTooltip]="'IČO'" [nbTooltipPlacement]="NbPosition.START" id="icoInput"
         [class.required-invalid]="businessId.invalid && businessId.touched" (blur)="validateInput()" >
    <button nbButton status="danger" appearance="ghost" class="icon-button" (click)="removeSelectedEmployer()">
      <nb-icon icon="close-square-outline"></nb-icon>
    </button>
  </div>
  <div *ngIf="disabledSelected" @slideInOut class="business-id-input-animated">
  <label for="nameInput">Nazov spoločnosti:</label>
  <input maxlength="8" class="autocompleteInput" [formControl]="companyName" readonly nbInput name="name" [placeholder]="'Názov spoločnosti'"
         [nbTooltip]="'Názov spoločnosti'" [nbTooltipPlacement]="NbPosition.START" id="nameInput">
  <label for="addressInput">Adresa spoločnosti:</label>
  <input maxlength="8" class="autocompleteInput" [formControl]="companyAddress" readonly nbInput name="address" [placeholder]="'Addressa spoločnosti'"
         [nbTooltip]="'Addressa spoločnosti'" [nbTooltipPlacement]="NbPosition.START" id="addressInput">
  </div>
</app-dialog-wrapper>
