import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mins2time'
})
export class Mins2timePipe implements PipeTransform {

  transform(value: number): string {
    return Math.floor(value / 60).toFixed(0) + ':' + (value % 60).toLocaleString(undefined, {minimumIntegerDigits: 2});
  }

}
