import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
  CategorizationProviderService,
  EmployerService,
  OccupationCategorizationApiModel,
  OccupationCategorizationNewApiModel,
  OccupationCategorizationService, OccupationCategorizationUpdateApiModel,
  Provider
} from 'src/api-new';
import {QuickForm} from 'src/app/modules/shared/components/quick-form/quick-form.component';
import {TableLoaderService} from 'src/app/services/table-loader.service';
import {SubscriptionContainer} from 'src/app/utils';

@Component({
  selector: 'app-add-edit-categorization-modal',
  templateUrl: './add-edit-categorization-modal.component.html',
  styleUrls: ['./add-edit-categorization-modal.component.scss']
})
export class AddEditCategorizationModalComponent implements OnInit, OnDestroy {

  actions = [
    {
      label: 'Zrušiť',
      status: "basic",
      click: () => this.ref.close(false)
    },
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => this.save(),
      disabled: () => this.fg.invalid || this.loadingEmployers
    }
  ];

  categorization: OccupationCategorizationApiModel;
  fg: UntypedFormGroup;
  formDef: QuickForm;
  providers: Array<Provider> = [];
  editMode = false;
  loadingEmployers = true;
  categorizationProviderId: number;

  loading$: Observable<boolean>;
  selectEmployer$: Observable<Array<{ label: string, value: number }>>;
  reqSub = Subscription.EMPTY;
  subs = new SubscriptionContainer();

  constructor(
    private tableLoader: TableLoaderService,
    private ref: NbDialogRef<AddEditCategorizationModalComponent>,
    private categorizationProviderService: CategorizationProviderService,
    private occupationCategorizationService: OccupationCategorizationService,
    private employerService: EmployerService,
    private nbToastr: NbToastrService
  ) {
  }

  ngOnInit(): void {
    this.subs.add = this.categorizationProviderService.categorizationProviderGetCurrentCategorizationProvider()
      .subscribe((res) => {
        this.categorizationProviderId = res.providerId;
      });

    this.selectEmployer$ = this.employerService.employerGetAllEmployers().pipe(
      map((provider) => provider.map(provider => ({label: provider.name ?? '', value: provider.id}))),
      tap(() => this.loadingEmployers = false)
    );

    this.formDef = {
      controls: [
        {
          formControlName: 'employerId',
          placeholder: 'Zamestnávateľ',
          type: 'select',
          optionsObservable: this.selectEmployer$,
          validators: [Validators.required],
        },
        {
          formControlName: 'number',
          placeholder: 'Číslo kategorizácie',
          type: 'text',
          validators: [Validators.required],
        },
        {
          formControlName: 'validSince',
          placeholder: 'Platná od',
          type: 'date',
          validators: [Validators.required],
        },
        {
          formControlName: 'validUntil',
          placeholder: 'Platná do',
          type: 'date',
        }
      ]
    };

    this.editMode = this.categorization ? true : false;
  }

  formInit(fg: UntypedFormGroup) {
    this.fg = fg;
    if (this.editMode) {
      this.fg.controls['employerId'].disable();
      this.fg.patchValue({
        employerId: this.categorization.employerId,
        number: this.categorization.categorizationName,
        validSince: this.categorization.validSince ? new Date(this.categorization.validSince) : undefined,
        validUntil: this.categorization.validUntil ? new Date(new Date(this.categorization.validUntil).setHours(0)) : undefined
      });
    }

  }

  isFormValid() {
    if (this.fg.invalid) return false;
    return true;
  }

  cancel() {
    this.ref.close(false);
  }

  save() {

    this.tableLoader.set(true);

    if (this.editMode) {
      const model: OccupationCategorizationUpdateApiModel = {
        id: this.categorization.id,
        categorizationName: this.fg.value.number,
        validSince:  new Date(this.fg.value.validSince.setHours(12)).toISOString(),
        validUntil: this.fg.value.validUntil ? new Date(this.fg.value.validUntil.setHours(12)).toISOString() : undefined,
      }
      this.reqSub = this.occupationCategorizationService.occupationCategorizationUpdateCategorization(model).subscribe(
        () => this.ref.close(model),
        (err) => {
          this.tableLoader.set(false);
          this.nbToastr.danger('Chyba v komunikácii so serverom ' + err.error.error, err.status, {duration: 5000});
        }
      );
    } else {

      const dto: OccupationCategorizationNewApiModel = {
        employerId: this.fg.value.employerId,
        categorizationName: this.fg.value.number,
        validSince: new Date(this.fg.value.validSince.setHours(12)).toISOString(),
        validUntil: this.fg.value.validUntil ? new Date(this.fg.value.validUntil.setHours(12)).toISOString() : undefined,
      }

      this.reqSub = this.occupationCategorizationService.occupationCategorizationCreateCategorization(dto).subscribe(
        () => this.ref.close(true),
        (err) => {
          this.tableLoader.set(false);
          this.nbToastr.danger('Chyba v komunikácii so serverom ' + err.error.error, err.status, {duration: 5000});
        }
      )
    }

    this.subs.add = this.reqSub;
  }

  ngOnDestroy() {
    this.subs.dispose();
  }

}
