<mat-dialog-actions>
  <div class="content">
    <div class="title">
      Vymazaním tohto používateľa sa vymažú všetky s ním spojené záznamy.
    </div>
    <div class="divider"></div>

    <div class="actions">
      <button mat-flat-button color="primary" (click)="close()">Zavrieť</button>
      <button mat-flat-button color="warn" (click)="confirm()">Vymazať</button>
    </div>

  </div>

</mat-dialog-actions>
