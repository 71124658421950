import { Component, OnInit } from '@angular/core';
import {DialogAction} from "../../../shared/components/dialog-wrapper/dialog-wrapper.component";
import {NbDialogRef, NbPosition, NbToastrService} from "@nebular/theme";
import {
  OrderDetailsApiModel,
  OrderService
} from "../../../../../api-new";
import {Router} from "@angular/router";
import {AssessmentMiddleService} from "../../../../services/assessment-middle.service";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-user-calendar-dialog',
  templateUrl: './user-calendar-dialog.component.html',
  styleUrls: ['./user-calendar-dialog.component.scss']
})
export class UserCalendarDialogComponent implements OnInit {

  NbPosition = NbPosition;

  dialogEmployeeAssessmentId:number;
  dialogAppointmentStatus:string;
  dialogProvider:string;
  dialogOrder: OrderDetailsApiModel;
  dialogEmployeeId:number;

  actions: Array<DialogAction> = [
    {label: 'Zrušiť', status: 'basic', click: () => this.close()},
    {
      label: 'Prejsť na posudok',
      status: 'primary',
      click: () => this.goToAssessment(),
      disabled: () => {
        return false;
      }
    },
    {
      label: 'Vytlačiť',
      status: 'primary',
      click: () => this.printAssessment(),
    }
  ];

  constructor(private ref: NbDialogRef<UserCalendarDialogComponent>,
              private assessmentMiddleService: AssessmentMiddleService,
              private orderService: OrderService,
              private router: Router,
              private nbToastr: NbToastrService,) { }

  ngOnInit(): void {
    this.orderService.orderGetOrderDetails(this.dialogEmployeeAssessmentId).subscribe((order)=>{
      this.dialogOrder = order;
    },error => {
      this.nbToastr.danger('Problém pri načítaní dát.','Kalendár Detail',{duration: 5000});
    });
  }

  close(): void {
    this.ref.close(false);
  }

  goToAssessment(): void {
    if (this.dialogAppointmentStatus == 'FINISHED') {
      this.router.navigateByUrl(`/zamestnanci/${this.dialogEmployeeId}/posudok/${this.dialogEmployeeAssessmentId}`)
        .then(r => this.ref.close(false));
    } else {
      this.router.navigateByUrl(`/posudky/otvorene/posudok/${this.dialogEmployeeAssessmentId}`)
        .then(r => this.ref.close(false));
    }
  }

  printAssessment() {
    this.assessmentMiddleService.printAssessment(this.dialogOrder.id).subscribe(
      blob => {
        switch(blob.type) {
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            saveAs(blob, `posudok-${this.dialogOrder.id}.docx`);
            break;
          case "application/pdf":
            saveAs(blob, `posudok-${this.dialogOrder.id}.pdf`);
            break;
          default: break;
        }
      }
    )
  }
}
