<div class="input-with-icon" (click)="onAddressClick()">
  <input  *ngIf="oldInputDesign == true"  nbInput name="fullAddress" [placeholder]="'Adresa'" [nbTooltip]="'Adresa'" [nbTooltipPlacement]="NbPosition.START" [value]="message" readonly>
  <mat-form-field *ngIf="oldInputDesign == false" [formGroup]="addressForm">
    <mat-label>Adresa</mat-label>
    <textarea style="resize: none" matInput formControlName="fullAddress" readonly [placeholder]="'Adresa'"></textarea>
    <mat-error *ngIf="addressForm.controls?.fullAddress?.invalid">Adresa je povinná</mat-error>
  </mat-form-field>
  <button nbButton status="warning" appearance="ghost" class="icon-button">
    <nb-icon icon="edit-2-outline"></nb-icon>
  </button>
</div>
