import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { CustomIconsService } from "./services/custom-icons.service";
import { NbIconLibraries } from "@nebular/theme";
import { CustomIconDataToRegister } from "./interfaces/custom-icon.interface";
import { customIconsMedicloud } from "src/assets/constants/icons";
import {AddressApiModel, AssessmentTypeEnum} from "../api-new";

export interface Sort {
  prop: string;
  dir: string;
}

export function shiftDate(date: Date, delta: number): Date {
  return new Date(new Date(date).setDate(new Date(date).getDate() + delta));
}

export function controlInputPrice(price: string) {
  let priceArray = price.replace(/[^0-9, ^,]/g, '').split(',');
  switch(priceArray.length) {
    case 1: {
      (priceArray[0].length === 2 && priceArray[0].charAt(0) === '0')
      ? price = priceArray[0].substring(0, 1)
      : price = priceArray[0]
      break;
    }
    case 2: {
      (priceArray[0].length === 0)
      ? price = priceArray[1]
      : (priceArray[1].length > 2)
        ? price = priceArray[0] + ',' + priceArray[1].substring(0, 2)
        : price = priceArray[0] + ',' + priceArray[1]
      break;
    }
    case 3: {
      price = priceArray[0] + ',' + priceArray[1];
      break;
    }
    default: break;
  }
  return price;
}

export function isDateValid(date: any) {
  return (Object.prototype.toString.call(date) === "[object Date]") && (!isNaN(date.getTime())) ;
}

export function controlInputCompanyId(input: String) {
  let companyId = input.replace(/[^0-9, ^,]/g, '').split(',')[0].replace(' ', '');
  if(companyId.length >= 8) companyId = companyId.substr(0, 8);
  return companyId;
}

export class SubscriptionContainer {
  private subs: Subscription[] = [];

  set add(s: Subscription) {
      this.subs.push(s);
  }

  dispose() {
      this.subs.forEach(s => s.unsubscribe());
  }
}

export function customIconsInitializeFactory(
  iconLibraries: NbIconLibraries,
  customIconsService: CustomIconsService,
): () => Observable<CustomIconDataToRegister> {
  return () => customIconsService.iconDataToRegister$(customIconsMedicloud.iconList).pipe(
    tap((iconsDataToRegister) => iconLibraries.registerSvgPack(
      customIconsMedicloud.packName,
      iconsDataToRegister
    )),
  );
}

export function timeStringToMinutes(
  timeString: string // hh:mm
): number {
  const array = timeString.split(":");
  return (parseInt(array[0], 10) * 60) + (parseInt(array[1], 10));
}

export function dateToTimeString(
  date: Date
): string {
  let minutes = date.getMinutes().toString();
  minutes = minutes.length > 1 ? minutes : `0${minutes}`;
  return `${date.getHours()}:${minutes}`;
}

export function getMinutesFromDate(
  date: Date
): number {
  return date.getHours() * 60 + date.getMinutes();
}

export function getHoursFromTimeString(
  timeString: string // hh:mm
): number {
  return parseInt(timeString.split(":")[0]);
}

export function resetTimeInDate(
  date: Date
): Date {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function formatDate(data:string): string {
  if (data == '' || data == null) {
    return '';
  }
  const date = new Date(data);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear().toString();
  day = day.padStart(2, '0');
  month = month.padStart(2, '0');
  return `${day}.${month}.${year}`;
}

export enum typeOfItem {
  RISK_FACTOR = "riskFactor",
  WORK_ACTIVITY = "workActivity"
}

export function buildAddress(addressApiModel: AddressApiModel): string {
  if(addressApiModel == null) {
    return '';
  }
  const { streetName, cityName, zipValue, countryName, registerNumber, descriptiveNumber } = addressApiModel;
  const regAndDescNumbers = () => `${descriptiveNumber && registerNumber ? registerNumber + "/" + descriptiveNumber : (registerNumber ? "" + registerNumber : descriptiveNumber)}`;
  const fullStreet = () => `${streetName??''}${regAndDescNumbers() ? " " + regAndDescNumbers() : ""}`;
  const cityFirst = () => `${cityName??''}${regAndDescNumbers() ? " " + regAndDescNumbers() : ""}`;
  const fillZip = () => ("" + zipValue).replace(" ", "") + "     ";
  const formattedZip = () => (fillZip().substring(0, 3) + " " + fillZip().substring(3, 5)).trim();
  let addressFields: string[] = [];

  if (!streetName && !cityName && !zipValue && !countryName && !registerNumber && !descriptiveNumber) return "";

  if (streetName) {
    addressFields.push(fullStreet());
  } else {
    addressFields.push(cityFirst());
  }
  addressFields.push((formattedZip() + " " + cityName).trim());
  addressFields.push(`${countryName || "Slovensko"}`);

  return addressFields.filter(fld => fld).join(", ").trim();
}


export function isDateInDateInterval(
  date: Date | string,
  start: Date | string,
  end: Date | string
): boolean {
  start = new Date((new Date(start)).toDateString());
  end = new Date((new Date(end)).toDateString());
  date = new Date(new Date(date).toDateString());
  return start <= date && date <= end;
}

export function getExaminationTypeName(type: AssessmentTypeEnum): string {
  return type === AssessmentTypeEnum.IMPORT ? 'Importovaná' :
    type === AssessmentTypeEnum.OUTPUT ? 'Výstupná' :
    type === AssessmentTypeEnum.INPUT ? 'Vstupná' :
    type === AssessmentTypeEnum.PERIOD ? 'Periodická' :
    type === AssessmentTypeEnum.REGULAR ? 'Jednorázová' : "NEZNAMY TYP PREHLIADKY";
}

export enum settingTypeOfCategory {
  COMPANY_SETTING = "companySetting",
  ACCOUNT_SETTING = "accountSetting"
}
