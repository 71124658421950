import { Component, OnInit } from '@angular/core';
import {DialogAction} from "../dialog-wrapper/dialog-wrapper.component";
import {NbDialogRef, NbPosition, NbToastrService} from "@nebular/theme";
import {LoginVerificationService} from "../../../../../api-new";
import {
  RegisteredUserDataGridComponent
} from "../../../registered-users/components/registered-user-data-grid/registered-user-data-grid.component";

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

  NbPosition = NbPosition;
  email:string;

  actions: Array<DialogAction> = [
    {label: 'Zrušiť', status: 'basic', click: () => this.close()},
    {
      label: 'Odoslať',
      status: 'primary',
      click: () => this.confirm(),
      disabled: () => {
        return this.email == null || this.email == '';
      }
    }
  ];

  constructor(
    private ref: NbDialogRef<RegisteredUserDataGridComponent>,
    private loginService: LoginVerificationService,
    private nbToastr: NbToastrService
  ) { }

  ngOnInit(): void {

  }

  close(): void {
    this.ref.close(false);
  }

  confirm():void {
    this.loginService.loginVerificationSendResetPasswordEmail(this.email)
      .subscribe(()=>{
        this.nbToastr.success('Obnovenie hesla bolo úspešne odoslané.','Obnovenie hesla:',{duration:5000});
        this.ref.close(false);
      },error => {
        this.nbToastr.danger('Obnovenie hesla zlyhalo.','Obnovenie hesla:',{duration:5000});
      });
  }

}
