/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressApiModel } from './addressApiModel';


export interface EmployeeDetailsUpdateApiModel { 
    id: number;
    addressDto: AddressApiModel;
    dateOfBirth: string;
    name: string;
    surname: string;
    personalNumber?: string;
    email?: string;
    phoneNumber?: string;
    birthNumber?: string;
    activeStatus: boolean;
}

