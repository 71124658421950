/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type AssessmentStatus = 'DRAFT' | 'ORDER' | 'FINISHED' | 'CONFIRMED';

export const AssessmentStatus = {
    DRAFT: 'DRAFT' as AssessmentStatus,
    ORDER: 'ORDER' as AssessmentStatus,
    FINISHED: 'FINISHED' as AssessmentStatus,
    CONFIRMED: 'CONFIRMED' as AssessmentStatus
};

