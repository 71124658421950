import { Component, OnInit} from '@angular/core';
import {NbDialogRef, NbDialogService, NbPosition, NbToastrService} from '@nebular/theme';
import {
  AddEditEmployerModalComponent
} from "../../../employers/components/add-edit-employer-modal/add-edit-employer-modal.component";
import {UntypedFormControl, Validators} from "@angular/forms";
import {buildAddress} from "../../../../utils";
import {Observable, of} from "rxjs";
import {AddressApiModel, CompanyDetailsApiModel, CompanyDetailsService} from "../../../../../api-new";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  AddEditProviderModalComponent
} from "../../../providers/components/add-edit-provider-modal/add-edit-provider-modal.component";
import {
  AddEditCategorizationProviderModalComponent
} from "../../../categorization-providers/components/add-edit-categorization-provider-modal/add-edit-categorization-provider-modal.component";

@Component({
  selector: 'app-add-edit-employer-dialog',
  templateUrl: './add-edit-employer-dialog.component.html',
  styleUrls: ['./add-edit-employer-dialog.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('void', style({ transform: 'translateX(100%)', opacity: 0 })),
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('0.5s ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.5s ease-in', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class AddEditEmployerDialogComponent implements OnInit  {

  NbPosition = NbPosition;
  editMode = false;
  businessId: UntypedFormControl;
  companyAddress: UntypedFormControl;
  companyName: UntypedFormControl;
  selectedEmployer?: {
    name: string,
    address: string,
    ico:string,
    addressDto: AddressApiModel;
  };
  suggestedEmployers$: Observable<CompanyDetailsApiModel[]>;
  selectedOption:string = '';
  selectedSuggestion = false;
  disabledSelected = false;
  employerFind:boolean;
  typeOfProvider:string;

  edam?:CompanyDetailsApiModel;

  actions = [
    {
      label: 'Zrušiť',
      status: "basic",
      click: () => {this.cancel()}
    },
    {
      label: 'Zadať manuálne',
      status: "warning",
      click: () => {this.setManually()},
      disabled: () => {
        return this.typeOfProvider == "inline";
      }
    },
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => {this.confirm()},
      disabled: () => {
        return !this.selectedEmployer;
      }
    }
  ];

  constructor(private ref: NbDialogRef<AddEditEmployerDialogComponent>,
              private nbDialog: NbDialogService,
              private nbToastr: NbToastrService,
              private companyDetail: CompanyDetailsService) {}

  ngOnInit(): void {
    this.employerFind = true;
    this.initializeForm();
    this.ref.onClose.subscribe(() => {
      if(!this.selectedSuggestion) {
        this.selectedOption = '';
        this.businessId.patchValue('');
      }
    });
  }

  private initializeForm(): void {
    this.businessId = new UntypedFormControl('',[Validators.required,
      Validators.pattern('^[0-9]*$'),Validators.minLength(6),Validators.maxLength(8)]);
    this.companyAddress = new UntypedFormControl();
    this.companyName = new UntypedFormControl();
    this.businessId.valueChanges.subscribe(value => {
      this.suggestEmployer(value);
    });
  }

  cancel(): void {
    this.selectedSuggestion = true;
    this.ref.close(false);
  }

  setManually():void {
    this.selectedSuggestion = true;
    if (this.typeOfProvider == "employer") {
      return this.ref.close({});
    }

    this.nbDialog.open(this.getProviderByType(this.typeOfProvider)).onClose.subscribe(()=>{
      this.ref.close(true);
    });
  }

  confirm(){
    if (this.typeOfProvider == "employer" || this.typeOfProvider == "inline") {
      return this.ref.close(this.edam!);
    }
    this.nbDialog.open(this.getProviderByType(this.typeOfProvider),{
      context: {
        selectedEmployer: this.selectedEmployer
      }
    }).onClose.subscribe(() => {
      this.ref.close(true);
    });
  }

  private getProviderByType(typeOfProvider:string){
    let returnTypeProvider:any = null;
    switch (typeOfProvider) {
      case 'employer':
        returnTypeProvider = AddEditEmployerModalComponent;
        break;
      case 'provider':
       returnTypeProvider = AddEditProviderModalComponent;
        break;
      case 'categorize':
        returnTypeProvider = AddEditCategorizationProviderModalComponent;
        break;
    }
    return returnTypeProvider;
  }

  validateInput() {
    if(!this.selectedSuggestion) {
      this.selectedSuggestion = true;
      return;
    }
    const control = this.businessId;
    let message = '';
    if (control && control.invalid) {
      if (control.errors?.required) {
        message = `IČO nesmie byť prázdny.`;
      } else if (control.errors?.minlength) {
        message = `IČO musí obsahovať presne min. ${control.errors.minlength?.requiredLength} číslic.`;
      } else if (control.errors?.maxlength) {
        message = `IČO musí obsahovať presne max. ${control.errors.maxlength?.requiredLength} číslic.`;
      } else if (control.errors?.pattern) {
        message = `IČO môže obsahovať iba čísla.`;
      }
      if (message) {
        this.nbToastr.warning(message, 'IČO formulár', {duration: 8000});
      }
    }
    this.businessId.addValidators([Validators.required, Validators.pattern('^[0-9]*$'),Validators.minLength(8)]);
  }

  public suggestEmployer(value: string) {
    if (value && value.length >= 6 && value.length <= 8 && !this.disabledSelected) {
      let ico = value;
      this.companyDetail.companyDetailsLoadCompanyDetail(value).subscribe((companyDetails) =>{
            this.selectedEmployer = {
              address:  buildAddress(companyDetails.addressDto!),
              ico: companyDetails.ico,
              name: companyDetails.name,
              addressDto: companyDetails.addressDto
            };
            this.businessId.clearValidators();
            this.businessId.setValue(companyDetails.ico);
            this.companyAddress.setValue(buildAddress(companyDetails.addressDto!));
            this.companyName.setValue(companyDetails.name);
            this.businessId.updateValueAndValidity();
            this.selectedOption = companyDetails.ico;
            this.selectedSuggestion = false;
            this.disabledSelected = true;
        this.employerFind = true;
        this.edam = companyDetails;
      },error => {
        this.employerFind = false;
        this.nbToastr.warning(`Dané IČO ${ico} sa nezhoduje zo žiadnou firmou.`, 'IČO formulár', {duration: 8000});
        }
      );
    }
  }

  removeSelectedEmployer() {
    this.disabledSelected = false;
    this.selectedOption = '';
    this.businessId.patchValue(this.selectedOption);
    this.companyAddress.setValue('');
    this.companyName.setValue('');
    this.selectedEmployer = undefined;
    this.suggestedEmployers$ = of();
  }

}
