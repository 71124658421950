import { Component, Input } from '@angular/core';
import { fadeInOutTrigger } from "../../../../animations";
import { NbComponentStatus } from "@nebular/theme/components/component-status";
import { NbComponentSize } from '@nebular/theme';

export interface DialogAction {
  label: string;
  click: () => any;
  disabled?: () => boolean;
  status?: NbComponentStatus;
  size?: NbComponentSize;
}

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  animations: [fadeInOutTrigger(300)]
})
export class DialogWrapperComponent {
  @Input() loading = false;
  @Input() message = 'Načítavanie...';
  @Input() actions: Array<DialogAction> = [];
}
