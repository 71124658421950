<app-dialog-wrapper [actions]="actions">
    <h3 class="col-12">{{editMode ? 'Upraviť detaily' : 'Detail'}} kategorizátora</h3>
    <form [formGroup]="fg" autocomplete="off">
        <div class="section">Základné údaje:</div>
        <div class="inputs-group">
              <mat-form-field appearance="fill">
                <mat-label>IČO *</mat-label>
                <div style="display: flex; flex-direction: row;">
                  <input matInput formControlName="companyIco" placeholder="Identifikačné číslo" [matTooltip]="'Identifikačné číslo organizácie'">
                  <button mat-icon-button (click)="onIcoClick()">
                   <nb-icon style="color: orange" icon="edit-2-outline"></nb-icon>
                  </button>
                </div>
                <mat-error *ngIf="fg.get('companyIco')?.hasError('required')">
                  IČO je povinné
                </mat-error>
              </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Meno kategorizátora *</mat-label>
            <div style="display: flex; flex-direction: row;">
              <textarea style="resize: none" matInput formControlName="companyName"></textarea>
            </div>
            <mat-error *ngIf="fg.get('companyName')?.hasError('required')">
              Meno kategorizátora je povinné
            </mat-error>
          </mat-form-field>
        </div>
                <label>Adresa sídla kategorizátora *</label>
                <app-edit-address [oldInputDesign]="false" [addressData]="provider?.addressDto" [isEditable]="editMode"
                    (outputAddressData)="onAddressDataChanged($event)" [triggerValidation]="triggerValidation"
                    [ngClass]="{hidden: hiddenControlSubject | async}"></app-edit-address>
        <ng-container *ngIf="!editMode">
            <div class="section">Prihlasovacie údaje:</div>
          <div class="inputs-group">
            <mat-form-field appearance="fill">
              <mat-label>Meno používateľa *</mat-label>
              <input matInput name="username" formControlName="username" [ngClass]="{hidden: hiddenControlSubject | async}" required>
              <mat-error *ngIf="fg.get('username')?.hasError('required')">
                Meno používateľa je povinné
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Email *</mat-label>
              <input matInput name="email" formControlName="email" [ngClass]="{hidden: hiddenControlSubject | async}" required>
              <mat-error *ngIf="fg.get('email')?.hasError('required')">
                Email je povinný
              </mat-error>
              <mat-error *ngIf="fg.get('email')?.hasError('email')">
                Zadajte platnú emailovú adresu
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Heslo *</mat-label>
              <input matInput name="password" formControlName="password" type="password" autocomplete="off" [ngClass]="{hidden: hiddenControlSubject | async}" required>
              <mat-error *ngIf="fg.get('password')?.hasError('required')">
                Heslo je povinné
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Zopakujte heslo *</mat-label>
              <input matInput name="repeatPassword" formControlName="repeatPassword" type="password" autocomplete="off" [ngClass]="{hidden: hiddenControlSubject | async}" required>
              <mat-error *ngIf="fg.get('repeatPassword')?.hasError('required')">
                Zopakujte heslo je povinné
              </mat-error>
              <mat-error *ngIf="passwordsNotMatch">
                Heslá sa nezhodujú
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
    </form>
</app-dialog-wrapper>
