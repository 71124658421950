<nb-card>
  <nb-card-body>
    <ng-content></ng-content>
    <div class="modal-actions">
      <button *ngFor="let action of actions"
              nbButton
              [status]="action?.status"
              [size]="action?.size ?? 'small'"
              (click)="action?.click()"
              [disabled]="action?.disabled ? action.disabled() : false">
        {{action.label}}
      </button>
    </div>
  </nb-card-body>
  <app-load-overlay [message]="message" *ngIf="loading" [@fadeInOut]></app-load-overlay>
</nb-card>
