import {
  AddressApiModel,
  AddressService,
  City,
} from "../../../../../api-new";
import {Component, OnInit, ChangeDetectorRef} from "@angular/core";
import {Observable, of} from "rxjs";
import {
  FormControl,
  FormGroup,
  ValidationErrors, ValidatorFn,
  Validators
} from "@angular/forms";
import {DialogAction} from "../dialog-wrapper/dialog-wrapper.component";
import {NbDialogRef} from "@nebular/theme";


export interface AddressFormControls {
  street: FormControl<string | null>;
  city: FormControl<string | null>;
  country: FormControl<string | null>;
  zip: FormControl<string | null>;
  registeredNumber: FormControl<number | null>;
  descriptiveNumber: FormControl<string | null>;
}

@Component({
  selector: 'app-edit-adress-dialog',
  templateUrl: './edit-adress-dialog.component.html',
  styleUrls: ['./edit-adress-dialog.component.scss']
})
export class EditAdressDialogComponent implements OnInit {

  suggestedStreets$: Observable<string[]> = of([]);
  suggestedCities$: Observable<City[]> = of([]);
  suggestedZips$: Observable<City[]> = of([]);
  suggestedCountries$: Observable<string[]> = of([]);

  editEmployeeForm: FormGroup<AddressFormControls>;

  addressData: AddressApiModel
  resetData: AddressApiModel;
  isEditable: boolean;
  formTitle: string;
  resetConfirmed: boolean = false;

  actions: Array<DialogAction> = [
    { label: 'Zrušiť', status: 'basic', click: () => this.close() },
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => this.save(),
      disabled: () => {
        return false;
      }
    }
  ];

  constructor(
    private ref: NbDialogRef<EditAdressDialogComponent>,
    private addressService: AddressService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.formTitle = 'Formular chyba';
    this.initializeForm();
    if (!this.addressData) {
      this.addressData = {countryName: "Slovensko"} as AddressApiModel;
    }
    if (this.addressData) {
      this.resetData = JSON.parse(JSON.stringify(this.addressData));
      this.editEmployeeForm.patchValue({
        street: this.addressData.streetName,
        city: this.addressData.cityName,
        zip: this.addressData.zipValue,
        country: this.addressData.countryName,
        registeredNumber: this.addressData.registerNumber,
        descriptiveNumber: this.addressData.descriptiveNumber
      });
    } else {
      this.resetData = {} as AddressApiModel;
    }
    this.ref.onClose.subscribe(() => {
      if (!this.resetConfirmed) {
        this.reset();
      }
    });
  }

  positiveNumberValidator(control: FormControl<string | null>): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null;
    }
    const valueAsString = value.toString();
    const number = parseFloat(valueAsString);
    if (number > 0 && valueAsString[0] !== '0') {
      return null; // Valid case
    }
    return { 'notPositive': true };
  }

  suggestionValidator(suggestions: any[], key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const valid = suggestions.some(suggestion => suggestion[key] === control.value);
      return valid ? null : { suggestionInvalid: true };
    };
  }

  initializeForm() {
    this.editEmployeeForm = new FormGroup<AddressFormControls>({
      zip: new FormControl<string | null>('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(5),
        Validators.minLength(5),
      ]),
      country: new FormControl<string | null>('', [
        Validators.required,
      ]),
      street: new FormControl<string | null>('', [
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      city: new FormControl<string | null>('', [
        Validators.required,

      ]),
      registeredNumber: new FormControl<number | null>(null, [
        Validators.pattern('^[0-9]+$'),
        this.positiveNumberValidator
      ]),
      descriptiveNumber: new FormControl<string | null>('', [
        Validators.pattern('^[0-9a-zA-Z]+$')
      ])
    });

    this.subscribeToValueChanges();
  }

  subscribeToValueChanges() {
    this.editEmployeeForm.controls.descriptiveNumber.valueChanges.subscribe(value => {
      this.addressData.descriptiveNumber = value ?? '';
    });

    this.editEmployeeForm.controls.registeredNumber.valueChanges.subscribe(value => {
      this.addressData.registerNumber = Number(value) || undefined;
    });

    this.editEmployeeForm.controls.street.valueChanges.subscribe(streetValue => {
      this.addressData.streetName = streetValue!;
      this.suggestStreets(streetValue!);
      this.updateValidatorsBasedOnStreet(streetValue);
    });

    this.editEmployeeForm.controls.city.valueChanges.subscribe(value => {
      this.addressData.cityName = value ?? '';
      this.suggestCities(value ?? '');
    });

    this.editEmployeeForm.controls.zip.valueChanges.subscribe(value => {
      this.addressData.zipValue = value ?? '';
      this.suggestZips(value ?? '');
    });

    this.editEmployeeForm.controls.country.valueChanges.subscribe(value => {
      this.addressData.countryName = value ?? '';
      this.suggestCountries(value ?? '');
    });
  }

  private updateValidatorsBasedOnStreet(streetValue: string | null | undefined) {
    if (streetValue && streetValue.length > 0) {
      this.editEmployeeForm.controls.descriptiveNumber.setValidators([Validators.required, Validators.pattern('^[0-9a-zA-Z]+$')]);
      this.editEmployeeForm.controls.registeredNumber.clearValidators();
    } else {
      this.editEmployeeForm.controls.descriptiveNumber.clearValidators();
      this.editEmployeeForm.controls.registeredNumber.setValidators([Validators.required, Validators.pattern('^[0-9]+$'), this.positiveNumberValidator]);
    }
    this.editEmployeeForm.controls.descriptiveNumber.updateValueAndValidity();
    this.editEmployeeForm.controls.registeredNumber.updateValueAndValidity();
    this.editEmployeeForm.controls.city.updateValueAndValidity();
    this.editEmployeeForm.controls.country.updateValueAndValidity();
    this.editEmployeeForm.controls.zip.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private suggestStreets(value: string) {
    if (value != null && value.length > 0) {
      this.addressService.addressGetStreetsSuggestions(value, 5).subscribe(suggestions => {
        this.suggestedStreets$ = of(suggestions.map(sug => sug.name));
        this.editEmployeeForm.controls.street.setValidators(this.suggestionValidator(suggestions, 'name'));
      });
    }
  }

  private suggestCities(value: string) {
    if (value != null && value.length > 0) {
      this.addressService.addressGetCitiesSuggestions(value, 5).subscribe(suggestions => {
        this.suggestedCities$ = of(suggestions);
        this.editEmployeeForm.controls.city.setValidators([Validators.required,this.suggestionValidator(suggestions, 'name')]);
        this.editEmployeeForm.updateValueAndValidity();
      });
    }
  }

  private suggestZips(value: string) {
    if (value != null && value.length > 0) {
      this.addressService.addressGetZipSuggestions(value, 5).subscribe(suggestions => {
        this.suggestedZips$ = of(suggestions);
        this.editEmployeeForm.controls.zip.setValidators([Validators.required,this.suggestionValidator(suggestions, 'psc')]);
        this.editEmployeeForm.updateValueAndValidity();
      });
    }
  }

  private suggestCountries(value: string) {
    if (value != null && value.length > 0) {
      this.addressService.addressGetCountriesSuggestions(value, 5).subscribe(suggestions => {
        this.suggestedCountries$ = of(suggestions.map(sug => sug.name));
        this.editEmployeeForm.controls.country.setValidators([Validators.required,this.suggestionValidator(suggestions, 'name')]);
      });
    }
  }

  onCitySelect(event: any) {
    if (event && event.name != null) {
      this.editEmployeeForm.controls.city.disable();
      this.editEmployeeForm.controls.zip.disable();
      this.editEmployeeForm.controls.city.setValue(event.name, {
        emitEvent: false
      });
      this.editEmployeeForm.controls.zip.setValue(event.psc, {
        emitEvent: false
      });
      this.editEmployeeForm.controls.city.enable();
      this.editEmployeeForm.controls.zip.enable();
    }
  }

  onZipSelect(event: any) {
    if (event && event.psc != null) {
      this.editEmployeeForm.controls.city.disable();
      this.editEmployeeForm.controls.zip.disable();
      this.editEmployeeForm.controls.city.setValue(event.name, {
        emitEvent: false
      });
      this.editEmployeeForm.controls.zip.setValue(event.psc, {
        emitEvent: false
      });
      this.editEmployeeForm.controls.city.enable();
      this.editEmployeeForm.controls.zip.enable();
      this.editEmployeeForm.controls.city.updateValueAndValidity();
      this.editEmployeeForm.controls.zip.updateValueAndValidity();
    }
  }

  save() {
    if (this.editEmployeeForm.valid) {
      this.resetConfirmed = true;
      const result = {
        addressData: this.addressData, confirmed: true
      };
      this.ref.close(result);
    } else {
      Object.values(this.editEmployeeForm.controls).forEach(control => {
        control.markAsTouched();
        this.cdr.detectChanges();
      });
    }
  }

  private reset() {
    if (this.addressData) {
      this.editEmployeeForm.patchValue({
        street: this.resetData.streetName,
        city: this.resetData.cityName,
        zip: this.resetData.zipValue,
        country: this.resetData.countryName,
        registeredNumber: this.resetData.registerNumber,
        descriptiveNumber: this.resetData.descriptiveNumber
      });
      this.cdr.detectChanges();
    }
  }

  close() {
    this.reset();
    this.ref.close();
  }

  getFieldName(fieldName: string): string {
    const fieldNames = {
      'zip': 'PSČ',
      'street': 'Ulica',
      'city': 'Mesto',
      'country': 'Krajina',
      'registeredNumber': 'Supisné číslo',
      'descriptiveNumber': 'Orientačné číslo'
    };

    return fieldNames[fieldName] || fieldName;
  }

}
