<div class="provider-filter">
  <nb-form-field>
    <nb-icon nbPrefix icon="provider" pack="icons-medicloud" class="custom-icon"></nb-icon>
    <nb-select
      fullWidth
      (selectedChange)="handleSelection($event)"
      (click)="selectorOpen()"
      [selected]="selectedProviderId"
      [disabled]="!canChoose()"
      placeholder="Zvoľte poskytovateľa">
      <nb-select-label *ngIf="selectedProviderName">
        {{ selectedProviderName }}<button nbButton ghost size="tiny" (click)="clearSelection()"><nb-icon icon="close-outline"></nb-icon></button>
      </nb-select-label>  
      <nb-option *ngFor="let provider of providers" [value]="provider.id">{{ provider.name }}</nb-option>
    </nb-select>
  </nb-form-field>
</div>

