/* eslint-disable no-unexpected-multiline */
import { Inject, Injectable } from '@angular/core';
import { AuthService, UserRole } from './auth.service';
import { BASE_PATH } from '../../api-new';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {

  userName?: string;
  tenantName?: string;
  profilePicture?: string;
  role?: UserRole;

  constructor(
    private authService: AuthService,
    @Inject(BASE_PATH) private basePath: string
  ) {
    authService.loggedIn.subscribe(
      (status) => {
        if (status) {
          const payload = authService.jwt ? JSON.parse(decodeURIComponent(escape(atob(authService.jwt?.split('.')
          [1].replace(/-/g, '+').replace(/_/g, '/'))))) : undefined;
          if (payload) {
            this.userName = payload.unique_name;
            this.tenantName = payload.tenantName;
            this.role = payload.role;
          }
          this.profilePicture = 'https://ui-avatars.com/api/?background=cccccc&rounded=true&name=' + this.userName?.replaceAll(' ', '+');
        } else {
          this.userName = undefined;
          this.profilePicture = undefined;
          this.role = undefined;
        }
      }
    );
  }

}
