<app-dialog-wrapper [actions]="actions">
  <h3>{{isEditable ? 'Upraviť adresu' : 'Detail adresa'}}</h3>
  <form [formGroup]="editEmployeeForm" autocomplete="off">
    <div class="personalData">
      <mat-form-field appearance="fill">
        <mat-label>Ulica</mat-label>
        <input matInput formControlName="street" [matAutocomplete]="autoStreet" placeholder="Ulica">
        <mat-autocomplete #autoStreet="matAutocomplete">
          <mat-option *ngFor="let option of suggestedStreets$ | async" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="editEmployeeForm.controls.street.hasError('minlength') || editEmployeeForm.controls.street.hasError('maxlength')">
          {{ getFieldName('street') }} musí obsahovať presne {{ editEmployeeForm.controls.street.errors?.minlength?.requiredLength || editEmployeeForm.controls.street.errors?.maxlength?.requiredLength }} číslic.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.street.hasError('suggestionInvalid')">
          Vyberte platnú hodnotu pre {{ getFieldName('street') }} zo zoznamu.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Súpisné číslo</mat-label>
        <input matInput formControlName="registeredNumber" placeholder="Súpisné číslo">
        <mat-error *ngIf="editEmployeeForm.controls.registeredNumber.hasError('required') && !editEmployeeForm.controls.registeredNumber.hasError('pattern')">
          {{ getFieldName('registeredNumber') }} nesmie byť prázdne.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.registeredNumber.hasError('pattern') && !editEmployeeForm.controls.registeredNumber.hasError('notPositive')">
          {{ getFieldName('registeredNumber') }} môže obsahovať iba čísla.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.registeredNumber.hasError('notPositive')">
          {{ getFieldName('registeredNumber') }} nesmie začínať nulou a musí obsahovať iba kladné čísla.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Orientačné číslo</mat-label>
        <input matInput formControlName="descriptiveNumber" placeholder="Orientačné číslo">
        <mat-error *ngIf="editEmployeeForm.controls.descriptiveNumber.hasError('pattern')">
          {{ getFieldName('descriptiveNumber') }} môže obsahovať iba čísla a písmená.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.descriptiveNumber.hasError('required')">
          {{ getFieldName('descriptiveNumber') }} nesmie byť prázdne.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Mesto</mat-label>
        <input matInput formControlName="city" [matAutocomplete]="autoCity" placeholder="Mesto">
        <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="onCitySelect($event.option.value)">
          <mat-option *ngFor="let option of suggestedCities$ | async" [value]="option">
            {{ option.name }} ({{ option.psc }})
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="editEmployeeForm.controls.city.hasError('required')">
          {{ getFieldName('city') }} nesmie byť prázdne.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.city.hasError('suggestionInvalid')">
          Vyberte platnú hodnotu pre {{ getFieldName('city') }} zo zoznamu.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>PSČ</mat-label>
        <input matInput formControlName="zip" [matAutocomplete]="autoZip" placeholder="PSČ">
        <mat-autocomplete #autoZip="matAutocomplete" (optionSelected)="onZipSelect($event.option.value)">
          <mat-option *ngFor="let option of suggestedZips$ | async" [value]="option">
            {{ option.name }} ({{ option.psc }})
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="editEmployeeForm.controls.zip.hasError('required')">
          {{ getFieldName('zip') }} nesmie byť prázdne.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.zip.hasError('pattern') || editEmployeeForm.controls.zip.hasError('minlength') || editEmployeeForm.controls.zip.hasError('maxlength')">
          {{ getFieldName('zip') }} musí obsahovať presne {{ editEmployeeForm.controls.zip.errors?.minlength?.requiredLength || editEmployeeForm.controls.zip.errors?.maxlength?.requiredLength }} číslic.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.zip.hasError('suggestionInvalid')">
          Vyberte platnú hodnotu pre {{ getFieldName('zip') }} zo zoznamu.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Krajina</mat-label>
        <input matInput formControlName="country" [matAutocomplete]="autoCountry" placeholder="Krajina">
        <mat-autocomplete #autoCountry="matAutocomplete">
          <mat-option *ngFor="let option of suggestedCountries$ | async" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="editEmployeeForm.controls.country.hasError('required')">
          {{ getFieldName('country') }} nesmie byť prázdna.
        </mat-error>
        <mat-error *ngIf="editEmployeeForm.controls.country.hasError('suggestionInvalid')">
          Vyberte platnú hodnotu pre {{ getFieldName('country') }} zo zoznamu.
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</app-dialog-wrapper>

