<div class="calendar">
    <div class="large">
        <div class="header">
            <button [shape]="'round'" nbButton size="small" (click)="shiftCalendarMonth(-1)">
                <nb-icon icon="chevron-left-outline"></nb-icon>
            </button>
            <span class="month-name">{{ calendarMonth | date : "LLLL YYYY" | titlecase }}</span>
            <button [shape]="'round'" nbButton size="small" (click)="shiftCalendarMonth(1)">
                <nb-icon icon="chevron-right-outline"></nb-icon>
            </button>
        </div>
        <div class="day-names">
            <div *ngFor="let dayName of dayNames" class="day-name">
                {{dayName}}
            </div>
        </div>
        <div *ngFor="let monthWeek of monthWeeks" class="week">
            <div *ngFor="let day of monthWeek.days" class="day"
                  [ngClass]="{
                    'not-current-month': day.date.getMonth() !== calendarMonth.getMonth(),
                    'selected': day.date.toDateString() === date.toDateString()}">
                <div class="date">
                    <span *ngIf="day.date.getMonth() !== calendarMonth.getMonth()" class="other-month">
                        {{day.date | date: "LLL" | titlecase}}.
                    </span>
                    <span>{{day.date.getDate()}}</span>
                </div>
                <div class="appointment" *ngFor="let appointment of day.appointments" >
                    <div class="employee" (click)="openDetails(appointment)"
                         [ngClass]="{'past': today.getTime() > day.date.getTime()}">
                        <div class="time">
                            {{appointment.plannedDate | date: "HH:mm"}}
                            <div class="specimen-tube" *ngIf="appointment.requireSample"></div>
                        </div>
                        <div class="name">
                            {{appointment.employeeName | slice:0:1}}. {{appointment.employeeSurname}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
