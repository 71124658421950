
<ng-container class="occupation-detail-row">
  <app-load-overlay *ngIf="loading$ | async" class="item-load-overlay"></app-load-overlay>

  <div *ngIf="occupationDetails$ | async as occupation" class="items">
    <div class="list-title" *ngIf="occupation.riskFactors.length">Rizikové faktory:</div>
    <div *ngFor="let riskFactor of occupation.riskFactors">
      <span>{{riskFactor.name}}</span>
      <button *ngIf="userRole === 'pzs_manager'"
        nbButton
        style="text-transform: capitalize"
        size="tiny"
        appearance="ghost"
        (click)="removeItem(occupation.occupationId, riskFactor.id)"
        status="danger"
      >
        <nb-icon icon="minus-outline"></nb-icon> Odobrať
      </button>
    </div>

    <div class="list-title" *ngIf="occupation.workActivities.length">Vybrané činnosti:</div>
    <div *ngFor="let workActivity of occupation.workActivities">
      <span>{{workActivity.name}}</span>
      <button *ngIf="userRole === 'pzs_manager'"
        nbButton
        style="text-transform: capitalize"
        size="tiny"
        appearance="ghost"
        (click)="removeItem(occupation.occupationId, workActivity.id)"
        status="danger"
      >
        <nb-icon icon="minus-outline"></nb-icon> Odobrať
      </button>
    </div>
  </div>

  <button
    *ngIf="userRole === 'pzs_manager'"
    nbButton
    size="small"
    style="margin: 16px"
    (click)="addItem()"
    [disabled]="loading$ | async"
  >
    <nb-icon icon="plus-outline"></nb-icon>
    Pridať faktor/činnosť
  </button>

</ng-container>
