/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type EmployeeActiveState = 'ACTIVE' | 'INACTIVE' | 'LAID_OFF';

export const EmployeeActiveState = {
    ACTIVE: 'ACTIVE' as EmployeeActiveState,
    INACTIVE: 'INACTIVE' as EmployeeActiveState,
    LAIDOFF: 'LAID_OFF' as EmployeeActiveState
};

