import { Component, OnInit } from '@angular/core';
import { Provider } from "../../../../../api-new";
import { ProvidersFilterService } from "../../../../services/provider-filter.service";
import { UserRole } from "../../../../services/auth.service";
import { UserDetailService } from "../../../../services/user-detail.service";

@Component({
  selector: 'app-provider-filter',
  templateUrl: './provider-filter.component.html',
  styleUrls: ['./provider-filter.component.scss']
})
export class ProviderFilterComponent implements OnInit {

  selectedProviderId: number | undefined = undefined;
  selectedProviderName: string | undefined = undefined;
  providerFilterSub: any;
  providers: Array<Provider> | null;


  constructor(
    private providerFilterService: ProvidersFilterService,
    private userDetailService: UserDetailService
  ) { }


  ngOnInit() {
    this.providerFilterService.loadedProviders$.subscribe(providers => {
      this.providers = providers
    })
    this.providerFilterSub = this.providerFilterService.selectedProvider$.subscribe(employee => {
      this.selectedProviderId = employee?.id;
      this.selectedProviderName = employee?.name;
    })
  }

  canChoose() {
    return this.userDetailService.role == UserRole.system_manager || this.userDetailService.role == UserRole.pzs_manager || this.userDetailService.role == UserRole.human_resource_manager;
  }

  ngOnDestroy() {
    this.providerFilterSub.unsubscribe()
  }


  handleSelection(providerId: number) {
    this.providerFilterService.changeProvider(providerId)
  }

  selectorOpen() {
    this.providerFilterService.reload()
  }

  clearSelection() {
    this.selectedProviderId = undefined;
    this.selectedProviderName = undefined;
    this.providerFilterService.selectedProvider$.next(null);
  }
}
