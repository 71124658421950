import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursmins'
})
export class HoursMinsPipe implements PipeTransform {

  transform(value: string): string {
    const time = value.split(":");
    return (+time[0]).toString() + 'h ' + (+time[1].toString()) + 'm';
  }
}

