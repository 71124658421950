export const customIconsMedicloud = {
  packName: 'icons-medicloud',
  iconList: [
    {name: 'provider', path: 'assets/icons/provider.svg'},
    {name: 'employer', path: 'assets/icons/employer.svg'},
    {name: 'helpCircle', path: 'assets/icons/help-circle.svg'},
    {name: 'healthCare', path: 'assets/icons/health-care.svg'},
    {name: 'employee', path: 'assets/icons/ico-zamestnanci.svg'},
    {name: 'examination', path: 'assets/icons/ico-posudky.svg'},
    {name: 'orders', path: 'assets/icons/ico-obj.svg'},
    {name: 'eligibles', path: 'assets/icons/ico-sposobilosti.svg'},
  ]
};
