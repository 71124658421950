<mat-dialog-content>
  <div *ngIf="loading" class="loader">
    <mat-spinner></mat-spinner>
  </div>
  <mat-card>
    <div class="content">
      <div class="header">
        <span class="title">
          Kategorizácia profesie
        </span>
        <mat-icon
        (click)="close()">
        close
      </mat-icon>
      </div>
      <div class="horizontal-divider"></div>
      <div class="left-right-context">
        <div class="left-column">
          <div class="profession-setting">
            <span>Nastavenie profesie</span>
          </div>
        </div>
        <div class="vertical-divider-full"></div>
        <div class="right-column">
            <div class="mid">
              <div class="mid-content">
                <div class="search">
                  <nb-form-field class="search-form-field">
                    <nb-icon nbPrefix icon="search-outline"></nb-icon>
                    <input type="text" nbInput
                           [(ngModel)]="nameSearch"
                           (ngModelChange)="searchByName({employerName: $event})"
                           placeholder='Vyhľadať názov alebo kód'
                           nbTooltip='Vyhľadať názov alebo kód'>
                  </nb-form-field>
                </div>
                <div style="display:flex;flex-direction: row">
                  <div class="vertical-divider"></div>
                  <div class="buttons-group">
                    <div class="active-indicator" [class.right]="selectedButton === CapabilityType.WORKACTIVITY"></div>
                    <div
                      #riskFactorButton
                      class="button"
                      (click)="changeItems(CapabilityType.RISKFACTOR)"
                      [ngClass]="{'inactive' : selectedButton == CapabilityType.WORKACTIVITY}">
                      Rizikové faktory
                    </div>
                    <div class="vertical-divider-custom"></div>
                    <div
                    #workActivityButton
                      class="button"
                      (click)="changeItems(CapabilityType.WORKACTIVITY)"
                      [ngClass]="{'inactive' : selectedButton == CapabilityType.RISKFACTOR}">
                      Vybrané činnosti
                    </div>
                  </div>
                </div>

              </div>
            </div>

        </div>
      </div>
      <div class="horizontal-divider"></div>
      <div class="left-right-context-2">
        <div>
          <div class="left-column-content">
            <form [formGroup]="fg" (ngSubmit)="save()">
              <mat-form-field appearance="fill">
                <mat-label>Názov profesie</mat-label>
                <input matInput formControlName="name" placeholder="Názov profesie">
                <mat-error *ngIf="fg.get('name')?.invalid">Názov profesie je povinný</mat-error>
              </mat-form-field>
              <div class="mat-slide-group">
                <div class="row">
                  <nb-toggle formControlName="valid" [checked]="fg.get('valid')?.value" style="scale: 1.6;"></nb-toggle>
                  <span class="row-title">Aktivna profesia</span>
                </div>
                <span>
                  Sprístupniť profesiu pri vytvorení nového kontraktu zamestnanca.
                </span>
                <div class="row">
                  <nb-toggle formControlName="completePeriodicCheck" [checked]="fg.get('completePeriodicCheck')?.value" style="scale: 1.6;"></nb-toggle>
                  <span class="row-title">Periodické plánovanie faktorov</span>
                </div>
                <span>
                   V prídpade expirácie akéhokoľvek faktoru bude objednávka obsahovať všetky položky.
                </span>
              </div>
            </form>
          </div>

                    </div>
        <div class="vertical-divider-full-2"></div>
        <div class="right-column-content">

            <mat-accordion multi class="example-headers-align">
              <div *ngFor="let group of groupedData">
              <mat-expansion-panel
              (opened)="group.open = true"
              (closed)="group.open = false">
                <mat-expansion-panel-header>
                  <mat-panel-title  [ngClass]="{ 'truncate-title': !group.open, 'full-title': group.open }">
                    <div style="display: flex;flex-direction: row;align-content: center;align-items: center;justify-content: space-between">
                      <div>
                        <div [ngClass]="{ 'truncate-span': !group.open, 'full-span': group.open }">
                          {{ group.items[0].code }} - {{ group.title }}
                        </div>
                      </div>
                      <div *ngIf="selectedItemInGroup(group)">
                        <mat-chip-set>
                          <mat-chip>
                            Priradený
                          </mat-chip>
                        </mat-chip-set>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div  *ngFor="let item of group.items" class="item" (click)="handleItemSelection(item, group)">
                  <div class="item-content" [ngClass]="{'disabled': item.disabled}">
                    <mat-checkbox
                    [(ngModel)]="item.selected"
                    (change)="handleItemSelection(item, group)"
                    [disabled]="item.disabled">
                    </mat-checkbox>
                    <mat-icon>error_outline</mat-icon>
                    <span> {{ item.name }} ({{ item.code }})</span>
                    <span>kategórie {{item.category}}</span>
                  </div>
                </div>
              </mat-expansion-panel>
              </div>
            </mat-accordion>
        </div>
      </div>
      <div class="info-bar"
      (click)="showInfoBar = !showInfoBar"
      [class.open]="showInfoBar"
      [class.closed]="!showInfoBar">
        <div class="cancel-icon" *ngIf="showInfoBar" (click)="showInfoBar = false;$event.stopPropagation()">
          <mat-icon >cancel</mat-icon>
        </div>
        <div class="visible-icon" *ngIf="!showInfoBar" (click)="showInfoBar = true;$event.stopPropagation()">
          <mat-icon >visibility</mat-icon>
        </div>
        <div class="info-bar-context">
          <mat-icon>error_outline</mat-icon>
          <span>Počet rizikových faktorov</span>
          <div class="bubble">
            {{ selectedRiskFactorCount }}
          </div>
          <div class="info-bar-context">
            <mat-icon>work_outline</mat-icon>
            <span>Počet vybraných činností faktorov</span>
            <div class="bubble">{{ selectedWorkActivityCount }}</div>
          </div>
          <div class="confirm-button" (click)="onConfirm(); $event.stopPropagation()">Uložiť zmeny</div>
        </div>
      </div>
    </div>
  </mat-card>
</mat-dialog-content>
