import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridfilterService {

  private filter: any;

  public getFilter() {
    return this.filter;
  }

  public setFilter(value: any) {
    this.filter = value;
  }

  constructor() { }
}
