<app-dialog-wrapper [actions]="actions">
  <h3 class="col-12">{{ editMode ? 'Upraviť detaily' : 'Detail' }} poskytovateľa</h3>

  <form [formGroup]="fg" autocomplete="off">
    <div class="section">Základné údaje:</div>

    <div class="inputs-group">
      <mat-form-field appearance="fill">
        <mat-label>IČO</mat-label>
        <div style="display: flex;flex-direction: row" (click)="onIcoClick()">
          <input matInput placeholder="Identifikačné číslo" formControlName="companyIco" matTooltip="Identifikačné číslo organizácie" />
          <button mat-icon-button style="color: orange">
            <nb-icon icon="edit-2-outline"></nb-icon>
          </button>
        </div>
        <mat-error *ngIf="fg.get('companyIco')?.hasError('required')">IČO je povinné</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Meno poskytovateľa</mat-label>
        <input matInput formControlName="companyName" required />
        <mat-error *ngIf="fg.get('companyName')?.hasError('required')">Meno poskytovateľa je povinné</mat-error>
      </mat-form-field>
    </div>

    <app-edit-address
      [oldInputDesign]="false"
      [addressData]="address"
      [isEditable]="editMode"
      (outputAddressData)="onAddressDataChanged($event)"
      [triggerValidation]="triggerValidation"
    >
    </app-edit-address>

    <ng-container *ngIf="!editMode">
      <div class="inputs-group">
        <mat-form-field appearance="fill">
          <mat-label>Aktívny od</mat-label>
          <input matInput [matDatepicker]="datepicker1" formControlName="effectiveSince" required />
          <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
          <mat-datepicker #datepicker1></mat-datepicker>
          <mat-error *ngIf="fg.get('effectiveSince')?.hasError('required')">Dátum začiatku je povinný</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="!editMode">
      <div class="section">Prihlasovacie údaje:</div>
      <div class="inputs-group">
        <mat-form-field appearance="fill">
          <mat-label>Meno používateľa</mat-label>
          <input matInput formControlName="username" required />
          <mat-error *ngIf="fg.get('username')?.hasError('required')">Meno používateľa je povinné</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Email *</mat-label>
          <input matInput formControlName="email" required />
          <mat-error *ngIf="fg.get('email')?.hasError('required')">Email je povinný</mat-error>
          <mat-error *ngIf="fg.get('email')?.hasError('email')">Zadajte platnú emailovú adresu</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Heslo</mat-label>
          <input matInput formControlName="password" type="password" autocomplete="off" required />
          <mat-error *ngIf="fg.get('password')?.hasError('required')">Heslo je povinné</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Zopakujte heslo</mat-label>
          <input matInput formControlName="repeatPassword" type="password" autocomplete="off" required />
          <mat-error *ngIf="fg.get('repeatPassword')?.hasError('required')">Zopakujte heslo je povinné</mat-error>
          <mat-error *ngIf="fg.get('repeatPassword')?.value !== fg.get('password')?.value">Heslá sa nezhodujú</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </form>
</app-dialog-wrapper>
