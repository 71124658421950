import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NbDialogService, NbPosition } from "@nebular/theme";
import { AddressApiModel } from "../../../../../api-new";
import { EditAdressDialogComponent } from "../edit-adress-dialog/edit-adress-dialog.component";
import { buildAddress } from "../../../../utils";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit, OnChanges {

  @Input() addressData?: AddressApiModel;
  @Input() isEditable: boolean;
  @Input() triggerValidation?: boolean;
  @Input() oldInputDesign?:boolean = false;
  @Output() outputAddressData = new EventEmitter<AddressApiModel>();

  NbPosition = NbPosition;
  message: string;
  localAddressData: AddressApiModel | undefined;
  addressForm: FormGroup;

  constructor(private dialogService: NbDialogService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef
  )
  {
    this.addressForm = this.fb.group({
      fullAddress: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.localAddressData = this.addressData ? JSON.parse(JSON.stringify(this.addressData)) : null;
    this.updateMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('addressData' in changes) {
      this.localAddressData = this.addressData ? JSON.parse(JSON.stringify(this.addressData)) : null;
      this.updateMessage();
    }
    if ('triggerValidation' in changes) {
      if (this.triggerValidation) {
        this.validateAddress();
      }
    }
  }

  updateMessage() {
    this.message = this.localAddressData ? buildAddress(this.localAddressData) : '';
    this.addressForm.controls.fullAddress.setValue(this.message);
    if (this.triggerValidation) this.validateAddress();
  }

  validateAddress() {
    if (!this.localAddressData ) {
      this.addressForm.controls.fullAddress.markAsTouched();
      this.addressForm.controls.fullAddress.setErrors({ required: true });
    } else {
      this.addressForm.controls.fullAddress.setErrors(null);
    }
    this.cdr.detectChanges();
  }

  public onAddressClick(): void {
    const dialogRef = this.dialogService.open(EditAdressDialogComponent, {
      autoFocus:false,
      context: {
        addressData: this.localAddressData,
        isEditable: this.isEditable,
      },
    });

    dialogRef.onClose.subscribe(result => {
      if (result && result.addressData && result.confirmed) {
        this.localAddressData = result.addressData;
        this.updateMessage();
        this.outputAddressData.emit(this.localAddressData!);
      } else {
        this.validateAddress();
      }
    });
  }
}
