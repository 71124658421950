import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {
  AssessibleItemApiModel,
  CapabilityApiModel,
  CapabilityDefinitionsService,
  CapabilityType,
  OccupationApiModel,
  OccupationAssessibleItemUpdateApiModel,
  OccupationCategorizationApiModel,
  OccupationCategorizationService,
  OccupationCreateApiModel,
  OccupationService,
  OccupationUpdateApiModel
} from "../../../../../../api-new";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NbDialogRef, NbToastrService} from "@nebular/theme";
import { forkJoin } from 'rxjs';
import { da } from 'date-fns/locale';

export interface CustomCapability extends CapabilityApiModel {
  selected: boolean;
  disabled: boolean;
}

export interface GroupedOccupation {
  title: string;
  items: CustomCapability[];
  open: boolean;
}

@Component({
  selector: 'app-occupation-categorization-dialog',
  templateUrl: './occupation-categorization-dialog.component.html',
  styleUrls: ['./occupation-categorization-dialog.component.scss']
})
export class OccupationCategorizationDialogComponent implements OnInit,AfterViewInit{

  nameSearch: string | undefined;
  categorization:OccupationCategorizationApiModel;
  panelOpenState = false;
  selectedButton: CapabilityType = CapabilityType.RISKFACTOR;
  occupation: OccupationApiModel;
  currentEmployerId: number;
  filteredData: CapabilityApiModel[] = [];
  data: CapabilityApiModel[] = [];
  groupedData: GroupedOccupation[] = [];
  allGroupedData: GroupedOccupation[] = [];
  fg: FormGroup;
  editMode = false;
  loading = true;
  riskFactorState: GroupedOccupation[] = [];
  workActivityState: GroupedOccupation[] = [];
  selectedRiskFactorCount: number = 0;
 selectedWorkActivityCount: number = 0;
 showInfoBar: boolean = false;
 selectedItemsMap: Set<GroupedOccupation> = new Set();
 @ViewChild('riskFactorButton') riskFactorButton: ElementRef;
 @ViewChild('workActivityButton') workActivityButton: ElementRef;

  constructor(
    private fb: FormBuilder,
    private ref: NbDialogRef<OccupationCategorizationDialogComponent>,
    private occupationCategorizationService: OccupationCategorizationService,
    private occupationService: OccupationService,
    private capabilityDefinitionsService: CapabilityDefinitionsService,
    private toaster: NbToastrService,
    private cdr: ChangeDetectorRef
  ) {}


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.workActivityButton.nativeElement.click();
      this.riskFactorButton.nativeElement.click();
    }, 300);
  }

  ngOnInit(): void {
    this.initForm();
    if (this.occupation) {
      this.editMode = true;
      this.populateForm();
    }
    this.loadData();
  }

  private loadData(): void {
    this.selectedItemsMap = new Set<GroupedOccupation>();
    this.occupationCategorizationService.occupationCategorizationGetOccupationDetails(this.categorization.id, this.occupation.id).subscribe(
      (data) => {
        const buildCustomCapability = (item: AssessibleItemApiModel): CustomCapability => ({
          id: item.id,
          name: item.name,
          specification: item.specification.split(/ kat\b/)[0].trim(),
          code: item.code,
          category: item.categoryId ?? undefined,
          selected: true,
          disabled: false,
          mandatory: false,
          revisionNumber: 1,
          type: item.dtype === 'RiskFactor' ? 'RISK_FACTOR' : 'WORK_ACTIVITY',
        });
        const riskFactorItems = data.riskFactors.map(buildCustomCapability);
        const workActivityItems = data.workActivities.map(buildCustomCapability);
        const allItems = [...riskFactorItems, ...workActivityItems];
        allItems.forEach((item) => {
          const existingGroup = Array.from(this.selectedItemsMap).find((group) => group.title === item.specification);
          if (existingGroup) {
            existingGroup.items.push(item);
          } else {
            const newGroup: GroupedOccupation = {
              title: item.specification,
              items: [item],
              open: false,
            };
            this.selectedItemsMap.add(newGroup);
          }
        });
      },
      (error) => this.toaster.warning('Problém pri načítaní detailov povolania', 'Chyba', { duration: 5000 })
    );

    let riskFactor: AssessibleItemApiModel[] = [];
    let workActivity: AssessibleItemApiModel[] = [];
    forkJoin([
      this.occupationCategorizationService.occupationCategorizationGetRiskFactors(this.categorization.id),
      this.occupationCategorizationService.occupationCategorizationGetWorkActivities(this.categorization.id),
    ]).subscribe(
      ([riskFactors, workActivities]) => {
        riskFactor = riskFactors;
        workActivity = workActivities;
        this.filteredData = [...riskFactor, ...workActivity].map((item): CapabilityApiModel => {
          const specification = item.specification.split(/ kat\b/)[0].trim();

          return {
            id: item.id,
            code: item.code,
            category: item.categoryId ?? undefined,
            mandatory: false,
            revisionNumber: 1,
            type: item.dtype === 'RiskFactor' ? 'RISK_FACTOR' : 'WORK_ACTIVITY',
            name: item.name,
            specification: specification
          };
        });
        this.filterDataByTabIndex();
        this.groupDataBySpecification();
        this.loading = false;
      },
      (error) => {
        this.toaster.warning('Problém s načítaním dát', 'Chyba', {duration: 5000});
        this.loading = false;
      }
    );

    // this.capabilityDefinitionsService.capabilityDefinitionsGetEmployerCapabilities(this.currentEmployerId).subscribe(
    //   (data) => {
    //     console.log(data,'data');
        // this.filteredData = data;
        // this.filterDataByTabIndex();
        // this.groupDataBySpecification();
    //   },
    //   (error) => this.toaster.warning('Problém s načítaním dát', 'Chyba', { duration: 5000 })
    // );
  }

  updateSelectedItemsCount(): void {
    let selectedRiskFactorCount = 0;
    let selectedWorkActivityCount = 0;
    this.selectedItemsMap.forEach((group) => {
      group.items.forEach((item) => {
        if (item.selected && item.type === CapabilityType.RISKFACTOR) {
          selectedRiskFactorCount++;
        } else if (item.selected && item.type === CapabilityType.WORKACTIVITY) {
          selectedWorkActivityCount++;
        }
      });
    });
    this.selectedRiskFactorCount = selectedRiskFactorCount;
    this.selectedWorkActivityCount = selectedWorkActivityCount;
    this.cdr.detectChanges();
  }

  private filterDataByTabIndex(): void {
    this.data = this.selectedButton === CapabilityType.RISKFACTOR
      ? this.filteredData.filter((item) => item.type === CapabilityType.RISKFACTOR)
      : this.filteredData.filter((item) => item.type === CapabilityType.WORKACTIVITY);
  }

    private groupDataBySpecification(): void {
      const groupedMap = new Map<string, CustomCapability[]>();
      this.data.forEach((item) => {
        const existingItem = this.findExistingItem(item);
        const occupation: CustomCapability = {
          ...item,
          selected: existingItem?.selected ?? false,
          disabled: false,
        };

        const key = item.specification;

        if (!groupedMap.has(key)) {
          groupedMap.set(key, []);
        }
        groupedMap.get(key)?.push(occupation);
      });

      this.groupedData = Array.from(groupedMap.entries()).map(([title, items]) => {
        items.sort((a, b) => this.extractNumberFromName(a.name) - this.extractNumberFromName(b.name));
        const selectedItem = items.find((item) => item.selected);
        if (selectedItem) {
          items.forEach((item) => {
            item.disabled = item !== selectedItem;
          });
        }

        return {
          title,
          items,
          open: this.findExistingGroupOpenState(title),
        };
      }).sort((a, b) => a.title.localeCompare(b.title));
      this.allGroupedData = this.groupedData;
    }

  private extractNumberFromName(name: string): number {
    const match = name.match(/(\d+)$/);
    return match ? parseInt(match[0], 10) : 0;
  }

  searchByName(event: any): void {
    const search = event.employerName.trim();
    this.nameSearch = search || undefined;
    this.applySearch();
  }

  removeGroupByTitle(title: string): void {
    const selectedGroup = Array.from(this.selectedItemsMap).find((group) => group.title === title);
    if (selectedGroup) {
      this.selectedItemsMap.delete(selectedGroup);
    }
  }

  applySearch(): void {
    this.loading = true;
    this.rebuildTheDataGroup();
    if (this.nameSearch && this.nameSearch.trim() !== '') {
      const searchTerm = this.nameSearch.toLowerCase();
      this.groupedData = this.allGroupedData
        .map((group) => {
          const filteredItems = group.items.filter(
            (item) =>
              item.name.toLowerCase().includes(searchTerm) ||
              item.code.toLowerCase().includes(searchTerm) ||
              group.title.toLowerCase().includes(searchTerm)
          );
          const updatedItems = filteredItems.map((filteredItem) => {
            const selectedGroup = Array.from(this.selectedItemsMap).find(
              (selectedGroup) => selectedGroup.title === group.title
            );
            const selectedItem = selectedGroup?.items.find(
              (selectedItem) => selectedItem.id === filteredItem.id
            );
            return selectedItem || filteredItem;
          });

          return {
            title: group.title,
            items: updatedItems,
            open: group.open,
          };
        })
        .filter((group) => group.items.length > 0);
    } else {
      this.rebuildTheDataGroup();
      this.groupedData = this.allGroupedData;
    }

    this.updateSelectedItemsCount();
    this.loading = false;
    this.cdr.detectChanges();
  }

  initForm(): void {
    this.fg = this.fb.group({
      name: ['', [Validators.required]],
      valid: [true],
      completePeriodicCheck: [false],
    });
  }

  populateForm(): void {
    this.fg.patchValue({
      name: this.occupation?.name,
      valid: this.occupation?.valid,
      completePeriodicCheck: this.occupation?.allRiskFactorExam,
    });
  }

  close(): void {
    this.ref.close(false);
  }

  save(): void {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      return;
    }
  }


  toggleSelection(item: CustomCapability, group: GroupedOccupation): void {
    this.showInfoBar = true;
    if (item.selected) {
      if (!Array.from(this.selectedItemsMap).some((existingGroup) => existingGroup.title === group.title)) {
        this.selectedItemsMap.add(group);
      }
      group.items.forEach((i) => (i.disabled = true));
      item.disabled = false;
    } else {
      this.removeGroupByTitle(group.title);
      group.items.forEach((i) => {
        i.disabled = false;
      });
      item.disabled = false;
    }

    this.groupedData = [...this.groupedData];
    this.cdr.detectChanges();
    this.updateSelectedItemsCount();
  }

  handleItemSelection(item: CustomCapability, group: GroupedOccupation, event?: Event): void {
    if (item.disabled) {
      return;
    }
    item.selected = !item.selected;
    this.toggleSelection(item, group);
  }


  private rebuildTheDataGroup(): void {
    const groupedMap = new Map<string, CustomCapability[]>();

    this.allGroupedData.forEach((group) => {
      group.open = false;

      const hasSelectedItem = Array.from(this.selectedItemsMap).some((selectedGroup) =>
        selectedGroup.title === group.title && selectedGroup.items.some((item) => item.selected)
      );

      group.items.forEach((item) => {
        const selectedGroup = Array.from(this.selectedItemsMap).find((selectedGroup) => selectedGroup.title === group.title);
        const selectedItem = selectedGroup?.items.find((selectedItem) => selectedItem.id === item.id);
        const rebuiltItem: CustomCapability = selectedItem
          ? { ...selectedItem }
          : { ...item, selected: false, disabled: false };
        rebuiltItem.disabled = hasSelectedItem && !rebuiltItem.selected;
        const key = group.title;
        if (!groupedMap.has(key)) {
          groupedMap.set(key, []);
        }
        groupedMap.get(key)?.push(rebuiltItem);
      });
    });

    this.groupedData = Array.from(groupedMap.entries()).map(([title, items]) => ({
      title,
      items,
      open: this.findExistingGroupOpenState(title),
    }));

    this.allGroupedData = [...this.groupedData];
    this.cdr.detectChanges();
  }

  changeItems(data:CapabilityType){
  if (this.selectedButton === CapabilityType.RISKFACTOR) {
    this.riskFactorState = this.allGroupedData;
  } else if (this.selectedButton === CapabilityType.WORKACTIVITY) {
    this.workActivityState = this.allGroupedData;
  }
  this.selectedButton = data;
  if (this.selectedButton === CapabilityType.RISKFACTOR && this.riskFactorState.length > 0) {
    this.groupedData = this.riskFactorState;
    this.allGroupedData = this.riskFactorState;
  } else if (this.selectedButton === CapabilityType.WORKACTIVITY && this.workActivityState.length > 0) {
    this.groupedData = this.workActivityState;
    this.allGroupedData = this.workActivityState;
  } else {
    this.filterDataByTabIndex();
    this.groupDataBySpecification();
  }
  this.nameSearch = undefined;
  this.rebuildTheDataGroup();
  this.updateSelectedItemsCount();
  }

  private findExistingItem(item: CapabilityApiModel): CustomCapability | undefined {
    const existingGroup = this.selectedButton === CapabilityType.RISKFACTOR
      ? this.riskFactorState.find((group) => group.items.some((i) => i.id === item.id))
      : this.workActivityState.find((group) => group.items.some((i) => i.id === item.id));

    return existingGroup?.items.find((i) => i.id === item.id);
  }

  private findExistingGroupOpenState(title: string): boolean {
    const existingGroup = this.selectedButton === CapabilityType.RISKFACTOR
      ? this.riskFactorState.find((group) => group.title === title)
      : this.workActivityState.find((group) => group.title === title);

    return existingGroup?.open ?? false;
  }

  onConfirm():void {
    const occupationUpdate: OccupationUpdateApiModel = {
      id: this.occupation.id,
      name: this.fg.value.name,
      allRiskFactorExam: this.fg.value.completePeriodicCheck,
      valid: this.fg.value.valid
    };
    this.occupationService.occupationUpdate(occupationUpdate).subscribe(
      ()=>{},(error) => this.toaster.warning('Problém pri úprave profesií', 'Chyba', { duration: 5000 })
    );

    const assessibleItemIds = Array.from(this.selectedItemsMap.values())
    .flatMap((group) => group.items)
    .filter((item) => item.selected)
    .map((selectedItem) => selectedItem.id);
    const occupationAssibleItemUpdate: OccupationAssessibleItemUpdateApiModel = {
      categorizationId: this.categorization.id,
      occupationId: this.occupation.id,
      assessibleItemIds: assessibleItemIds
    };
    this.occupationCategorizationService.occupationCategorizationAssignAssessibleItemsToOccupation(occupationAssibleItemUpdate).subscribe(
      ()=>{
        this.ref.close(true);
      }, (error) => this.toaster.warning('Problém pri priradovaní rizikových faktorov a pracovných činností', 'Chyba', { duration: 5000 })
    );
  }

  protected selectedItemInGroup(data:GroupedOccupation): boolean{
    return data?.items?.some((item) => item?.selected === true);
  }

  protected readonly CapabilityType = CapabilityType;

}
