/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type WeekDayEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const WeekDayEnum = {
    MONDAY: 'MONDAY' as WeekDayEnum,
    TUESDAY: 'TUESDAY' as WeekDayEnum,
    WEDNESDAY: 'WEDNESDAY' as WeekDayEnum,
    THURSDAY: 'THURSDAY' as WeekDayEnum,
    FRIDAY: 'FRIDAY' as WeekDayEnum,
    SATURDAY: 'SATURDAY' as WeekDayEnum,
    SUNDAY: 'SUNDAY' as WeekDayEnum
};

